import React from "react";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../Contexts/user-context";

const SpecialRouteMiddleware = (props) => {

    const token = localStorage.getItem('@user');
    const { user } = useContext(UserContext)

    const location = useLocation();

    if (!token) {
        return <Navigate to={{ pathname: "/login", state: { from: location } }} />;
    }

    if (user.emailCheck === true) {
        return <Navigate to={{ pathname: "/", state: { from: location } }} />;
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
};

export default SpecialRouteMiddleware;
