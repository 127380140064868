import React from "react";
import { useNavigate } from "react-router-dom";

import '../Header.css'

const HeaderLogo = React.memo(
    function HeaderLogo() {

        const location = useNavigate()

        return (
            <div className="header--logo">                
                    <span>
                        <img onClick={()=>{
                            location('/')
                        }} src='https://pumpkinstudio.com.br/wp-content/uploads/2022/10/logo-pumpkinStudio-e1666810549202.png' alt="logo" />
                    </span>               
            </div>
        )
    }
)

export default HeaderLogo