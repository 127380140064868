import React, { useState, createContext, useEffect } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const RaceContext = createContext();

const RaceProvider = ({ children }) => {

    const [races, setRaces] = useState();
    const [selectedRace, setSelectedRace] = useState();
    const [paidRaces, setPaidRaces] = useState();

    const getRaces = async () => {
        try {
            const response = await api.get('/race');
            const res = response.data;

            setRaces(res);    

        } catch (err) {
            toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message);
        }
    };    

    const getPaidRaces = async () => {
        try {
            const response = await api.get('/race/paid');
            const res = response.data;
            
            setPaidRaces(res);    

        } catch (err) {
            toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message);
        }
    };    

    return (
        <RaceContext.Provider value={{ races, setRaces, paidRaces, selectedRace, getRaces, getPaidRaces, setSelectedRace }}>
            {children}
        </RaceContext.Provider>
    )

};

export default RaceProvider;