import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { SignatureContext } from "../../Contexts/signature-context";
import './signature.css';
import { useEffect } from "react";
import { KnowMoreModal } from "./knowMoreModal";



const Signature = () => {

    const { plans, plan, setPlan, getPlans } = useContext(SignatureContext);

    const [showModal, setShowModal] = useState(false)
    const [subscribeType, setSubscribeType] = useState("member")

    const [yearlyOrMontly, setyearlyOrMontly] = useState("yearly")
    const [memberPrice, setMemberPrice] = useState(null)
    const [heroPrice, setHeroPrice] = useState(null)
    const [info, setInfo] = useState(" | No Plano Anual")

    const [membroMensal, setMembroMensal] = useState(null);
    const [membroAnual, setMembroAnual] = useState(null);
    const [heroiMensal, setHeroiMensal] = useState(null);
    const [heroiAnual, setHeroiAnual] = useState(null);

    useEffect(() => {
        getPlans()
    }, [])

    useEffect(() => {
        if (plans) {
            setMemberPrice(formatPrice(membroAnual?.minimum_price));
            setHeroPrice(formatPrice(heroiAnual?.minimum_price));
        }
    }, [plans, membroAnual, heroiAnual]);

    useEffect(() => {
        if (plans) {
            plans.forEach((plan) => {
                switch (plan.name) {
                    case "Membro Mensal":
                        setMembroMensal(plan);
                        break;
                    case "Membro Anual":
                        setMembroAnual(plan);
                        break;
                    case "Heroi Mensal":
                        setHeroiMensal(plan);
                        break;
                    case "Heroi Anual":
                        setHeroiAnual(plan);
                        break;
                    default:
                        break;
                }
            });
        }
    }, [plans])

    function formatPrice(price) {
        if (price) {
            const priceString = price?.toString();
            if (priceString.length <= 2) {
                return `0,${priceString}`;
            }
            const integerPart = priceString.slice(0, -2);
            const decimalPart = priceString.slice(-2);
            return `${integerPart},${decimalPart}`;
        }

    }

    const checkMonthYear = function (event) {
        const month = document.querySelector('.monthly');
        const year = document.querySelector('.yearly');

        if (event.target === month) {

            month.classList.add('active');
            year.classList.remove('active');
            setyearlyOrMontly("yearly")
            setMemberPrice(formatPrice(membroAnual?.minimum_price))
            setHeroPrice(formatPrice(heroiAnual?.minimum_price))
            setInfo(" | No Plano Anual")

        } if (event.target === year) {

            month.classList.remove('active');
            year.classList.add('active');
            setyearlyOrMontly("montly")
            setMemberPrice(formatPrice(membroMensal?.minimum_price))
            setHeroPrice(formatPrice(heroiMensal?.minimum_price))
            setInfo("")
        }

    }

    return (
        <>
            <section className="section-pricing ">
                <div className="container header-padding">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="header-section">
                                <div className="plans-buttons">
                                    <span className="monthly active" onClick={checkMonthYear}>Anual<span className="perc">-35%</span></span>
                                    <span className="yearly" onClick={checkMonthYear}>Mensal</span>
                                </div>
                                <h2 className="title">Apoie a Guilda</h2>
                                <p className="description">
                                    Faça parte de uma comunidade de RPG de mesa super engajada.
                                    São <strong>novos monstros todos os meses</strong> para os membros, além de <strong>metas extras</strong> com
                                    cargos no Discord e <strong>direito a voto nas novas produções e funcionalidades do portal da Guilda.</strong>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="plans d-flex align-items-center">


                                <div className="single-plan startup text-center">
                                    <div className="content">
                                        <h3 className="title">Membros</h3>

                                        <p className="description">Desbloqueie conteúdos e ganhe 10% de descontos nas aventuras antigas.</p>
                                        <div className="icon">
                                            <img src={require('../../assets/emblemas_membro.png')} />
                                        </div>
                                        <div className="price">
                                            <span className="dolar">R$</span>
                                            <span className="number">{memberPrice || ' ...'}</span>
                                            <span className="plan-name">Por Mês{info}</span>
                                        </div>

                                        {yearlyOrMontly === 'yearly' && <Link to='/signature-checkout'
                                            className="purchase-button"
                                            onClick={() => {
                                                setPlan(
                                                    { ...membroAnual, image: "../../assets/emblemas_membro.png" }
                                                )
                                            }}>Apoiar Agora
                                        </Link>}
                                        {yearlyOrMontly === 'montly' && <Link to='/signature-checkout'
                                            className="purchase-button"
                                            onClick={() => {
                                                setPlan(
                                                    { ...membroMensal, image: "../../assets/emblemas_membro.png" }
                                                )
                                            }}>Apoiar Agora
                                        </Link>}
                                        <div className="know-more-button"
                                            onClick={
                                                () => {
                                                    setSubscribeType("member");
                                                    setShowModal(true)
                                                }
                                            }>
                                            <p>{`Saber mais >`}</p>
                                        </div>

                                    </div>
                                </div>


                                <div className="single-plan agency active text-center">
                                    <div className="content">
                                        <h3 className="title">Heróis e Heroínas</h3>
                                        <p className="description">Desbloqueie conteúdos e tenha 50% de desconto nas aventuras antigas.</p>
                                        <div className="icon">
                                            <img src={require('../../assets/emblemas_hero.png')} />
                                        </div>
                                        <div className="price">
                                            <span className="dolar">R$</span>
                                            <span className="number">{heroPrice || ' ...'}</span>
                                            <span className="plan-name">Por Mês{info}</span>
                                        </div>
                                        {yearlyOrMontly === 'yearly' && <Link to='/signature-checkout'
                                            className="purchase-button"
                                            onClick={() => {
                                                setPlan(
                                                    { ...heroiAnual, image: "../../assets/emblemas_hero.png" }
                                                )
                                            }}>Apoiar Agora
                                        </Link>}
                                        {yearlyOrMontly === 'montly' && <Link to='/signature-checkout'
                                            className="purchase-button"
                                            onClick={() => {
                                                setPlan(
                                                    { ...heroiMensal, image: "../../assets/emblemas_hero.png" }
                                                )
                                            }}>Apoiar Agora
                                        </Link>}
                                        <div className="know-more-button"
                                            onClick={
                                                () => {
                                                    setSubscribeType("hero");
                                                    setShowModal(true)
                                                }
                                            }>
                                            <p>{`Saber mais >`}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <KnowMoreModal showModal={showModal} setShowModal={setShowModal} subscribeType={subscribeType} />
        </>
    )
}

export default Signature;