import React, { useState, useEffect, useContext, useRef } from "react";
import { CartContext } from "../../Contexts/cart-context";
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import './Carrinho.css';

import { CloseBtn } from "../utils/close-btn/CloseBtn";
import { UserContext } from "../../Contexts/user-context";


const Carrinho = React.memo(function Carrinho() {

    const location = useNavigate()

    const { cart, cartItems, getCart } = useContext(CartContext);
    const { user } = useContext(UserContext)

    const cartRef = useRef([]);
    const [openCart, setOpenCart] = useState(false);

    const handler = (e) => {
        if (!cartRef.current?.contains(e.target)) {
            setOpenCart(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    return (

        <div className="header--cart" id="header--cart" ref={cartRef} >
            <span onClick={() => { setOpenCart(!openCart) }}>
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                {cartItems.length != 0 && <span className="header--cartCount" >{cartItems.length}</span>}
            </span>


            {openCart && <>
                <div className="header--cartList activeCartList" id="header--cartList">
                    <CloseBtn onClick={() => { setOpenCart(!openCart) }} />

                    {cartItems.length != 0 && <h2>Seu Carrinho:</h2>}

                    {cartItems.length === 0 && <div className="cartEmpty" id="cartEmpty">
                        <h2>Seu carrinho está vazio</h2>
                        <span>Continue navegando e escolha à avontade</span>

                    </div>}

                    <div className="header--cartIn">
                        {cartItems.length > 0 && <div className="shopping-cart-items">

                            {cartItems.length > 0 && cartItems.map((cartitem) => (
                                <div key={cartitem.code._id}>
                                    <div className="clearfix" id={cartitem.code._id} >
                                        <div className="items-flex-intern">
                                            <img className="" src={cartitem.code.thumb} />
                                            <div className="items-inter">
                                                <span className="item-name">{cartitem.description}</span>
                                                <span className="item-price">
                                                    {"Preço: R$ "}
                                                    {cartitem && (
                                                        cartitem?.code?.type?.includes('Manual') ?
                                                            (cartitem.amount / 100).toFixed(2).replace(".", ",") :
                                                            (user?.plan === "member" ? ((cartitem.amount / 100 - cartitem.amount / 100 * 0.1)).toFixed(2).replace(".", ",") :
                                                                (user?.plan === "hero" ? ((cartitem.amount / 100 - cartitem.amount / 100 * 0.5)).toFixed(2).replace(".", ",") :
                                                                    (cartitem.amount / 100).toFixed(2).replace(".", ","))
                                                            )
                                                    )}
                                                </span>
                                            </div>
                                            {/*<span className="item-quantity">Quantidade: {cartitem.quantity}</span>*/}

                                            <div className="product-off fa fa-close" data-id={cartitem.code._id} onClick={
                                                async () => {
                                                    const chosedItem = { items: { _id: `${cartitem.code._id}` } };
                                                    const customerCart = `${cart.cart._id}`; // ⚠️ PRECISA INSERIR O CARRINHO DO CLIENTE DINAMICAMENTE
                                                    try {
                                                        const response = await api.put(`cart/${customerCart}/delete-item`, chosedItem);
                                                        const res = response.data;

                                                        if (res.error) {
                                                            console.error(res.message);
                                                            return false;
                                                        }
                                                        getCart()
                                                    } catch (err) {
                                                        console.error(err.message);
                                                    }
                                                }
                                            }>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            ))}

                        </div>}
                    </div>
                    <div className="items-flex-bottom">
                        {cartItems.length !== 0 && (
                            <div>
                                Total: R$
                                {(
                                    cartItems
                                        .map(item => {
                                            const price = item.amount / 100;

                                            return item?.code?.type?.includes('Manual') ?
                                                price :
                                                user?.plan === "member" && !item.type?.includes('Manual') ?
                                                    (price - price * 0.1) :
                                                    user?.plan === "hero" ?
                                                        (price - price * 0.5) :
                                                        price;
                                        })
                                        .reduce((accumulator, item) => accumulator + parseFloat(item), 0)
                                ).toFixed(2).replace(".", ",")}
                            </div>
                        )}

                        {cartItems.length != 0 && <div>
                            <button className="btn btn-primary button-guild" id="finalizarCompra" onClick={() => {
                                location('/checkout');
                            }}>
                                💰 Finalizar Compra
                            </button>
                        </div>}
                    </div>
                </div>
            </>
            }
        </div>
    )

})

export default Carrinho;