import React, { useState, createContext } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const UserContext = createContext();

const UserProvider = ({ children }) => {

  const [userInfo, setUserInfo] = useState(
    { name: '', surname: '', email: '', document: '' }
  );

  const [phoneInfo, setPhoneInfo] = useState(
    { country_code: "55", area_code: "", number: "" }
  )

  const [addressInfo, setAddressInfo] = useState(
    { line_1: "", number: '', zip_code: "", city: "", state: "", country: "BR" }
  );

  //PEGAR DADOS DO USUÁRIO

  const [user, setUser] = useState();

  const getMyAccount = async () => {

    try {
      const accountGetter = await api.get('customer/get-one');
      const data = accountGetter.data;

      setUser(data)

      setUserInfo({
        name: data?.name,
        surname: data?.surname,
        email: data?.email,
        document: data?.document || ""
      })
      setPhoneInfo({
        country_code: data?.phones?.mobile_phone?.country_code || "55",
        area_code: data?.phones?.mobile_phone?.area_code || "",
        number: data?.phones?.mobile_phone?.number || ""
      })
      setAddressInfo({
        line_1: data?.address?.line_1 || "",
        number: data?.address?.number || "",
        zip_code: data?.address?.zip_code || "",
        city: data?.address?.city || "",
        state: data?.address?.state || "",
        country: data?.address?.country || "BR"
      })

    } catch (error) {
      toast.warn("Erro:" + error);

    }
  };

  return (
    <UserContext.Provider value={{
      userInfo,
      setUserInfo,
      phoneInfo,
      setPhoneInfo,
      addressInfo,
      setAddressInfo,
      user,
      setUser,
      getMyAccount
    }}>
      {children}
    </UserContext.Provider>
  )

};

export default UserProvider;