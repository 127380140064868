import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import './EmailCheck.css'
import { UserContext } from '../../Contexts/user-context';
import { useNavigate } from 'react-router-dom';
import { SignatureContext } from '../../Contexts/signature-context';


function EmailCheckPage() {

  const { plan } = useContext(SignatureContext);

  const navigate = useNavigate();

  const { user, getMyAccount } = useContext(UserContext);

  const [emailCode, setEmailCode] = useState({
    email: user?.email,
    emailToken: ''
  });

  const [isLoading, setIsLoading] = useState(false)

  const SendConfirmEmail = async () => {

    window.addEventListener('submit', function (e) { e.preventDefault() })

    const validateFields = document.querySelectorAll('#frm-signin :invalid').length;
    if (validateFields !== 0) { return false }

    setIsLoading(true)

    await api.put('customer/confirm-email', emailCode)
      .then((response) => {

        toast.success("Conta confirmada");

        /* api.post('cart');

        api.post('transaction/bounty');
        toast.success("Infestação Noturna adicionada às suas aventuras."); */

        setIsLoading(false);

        const userInfoString = localStorage.getItem('@userInfo');
        const userInfo = JSON.parse(userInfoString);
        userInfo.emailCheck = true;
        const updatedUserInfoString = JSON.stringify(userInfo);
        localStorage.setItem('@userInfo', updatedUserInfoString);

        getMyAccount()

        if (plan) {
          navigate('/signature-checkout')
        }

        else {
          navigate('/')
        }

      })
      .catch((reason) => {

        toast.warn(reason.message);
        setIsLoading(false);
      });
  };

  const resendEmail = async () => {
    await api.post('customer/confirm-email/resend')
      .then((response) => {
        toast.success("E-mail reenviado");
      })
      .catch((reason) => {
        toast.warn(`O e-mail não pode ser reenviado, entre em contato com o suporte: ${reason.message}`);
      });
  };

  return (

    <div className="container-login-page">
      <div className="frm-main-container">
        <div className="inner">

          <span id='email-auth-title'>Código de Confirmação</span>
          <span id='email-auth-span'>Insira o código de confirmação recebido por e-mail</span>
          <form action="" className="frm-signin" id="frm-signin">
            <div className="frm-group">
              <input
                type='text'
                id='input-confirm-email'
                size="16"
                className="form-control form-control-lg"
                placeholder="Insira o código de confirmação aqui"
                required
                onChange={(e) => {
                  setEmailCode({ ...emailCode, emailToken: e.target.value })
                }}
              />
            </div>

            <div className="frm-group">
              <br />

              {!isLoading && <button className="" id="btn_send" type="submit" onClick={() => { SendConfirmEmail() }}>Confirmar</button>}
              {isLoading && <button disabled className="loadingBtn" type="submit" >{'CONFIRMANDO '}</button>}

            </div>
            <hr />
            <div className="text-center">
              <span id="resend-code" className="fp-link" onClick={() => {
                resendEmail()
              }}>Reenviar Código</span>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}

export default EmailCheckPage;
