import React, { useRef, useEffect } from "react";

export const KnowMoreModal = ({ showModal, setShowModal, subscribeType }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        if (showModal) {
            document.addEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [showModal, setShowModal]);

    const info = subscribeType === "member"
        ? {
            name: "Membro",
            infos: (
                <ul className="description">
                    <li>Desbloqueie os conteúdos premium.</li>
                    <li>Novos materiais todos os meses (Monstros, Itens, Mapas...).</li>
                    <li>Ganhe 10% de descontos nas aventuras antigas.</li>
                    <li>Acesso de Membro ao Discord.</li>
                    <li>Participe das Metas Batidas.</li>
                    <li>Voto para Metas Extras e Funcinalidades do Portal.</li>
                    <li>Nome nos créditos das criações como Membro.</li>
                    <li>Acesso ao Blog Restrito.</li>
                </ul>
            )
        }
        : {
            name: "Herói | Heroína",
            infos: (
                <ul className="description">
                    <li>Desbloqueie os conteúdos premium.</li>
                    <li>Novos materiais todos os meses (Monstros, Itens, Mapas...).</li>
                    <li>Ganhe 50% de descontos nas aventuras antigas.</li>
                    <li>Acesso de Herói | Heroína ao Discord.</li>
                    <li>Acesso as salas especiais do Discord.</li>
                    <li>Participe das Metas Batidas.</li>
                    <li>Voto vale 3x para Metas Extras e Funcinalidades do Portal.</li>
                    <li>Nome nos créditos das criações como Herói | Heroína.</li>
                    <li>Acesso ao Blog Restrito.</li>
                    <li>Acesso antecipado aos conteúdos de Fallgard (O Mundo da Guilda).</li>
                </ul>
            )
        };

    return (
        <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
            <div className="modal-dialog" role="document">
                <div ref={modalRef} className="modal-content cancel-modal">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{info?.name}</h5>
                        <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Fechar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {info?.infos}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={() => setShowModal(false)} style={{ color: 'white' }}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
