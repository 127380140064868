import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './Hero.css';

import { ProductContext } from "../../Contexts/product-context";

export default () => {

    const { principal, selected, setSelected } = useContext(ProductContext);
    const location = useNavigate();

    return (
        <>
            <div className="carousel" data-ride="carousel">
                <div className="carousel-inner ">
                    {principal &&

                        <div className="carousel-item active">
                            <section className="featured" style={{ backgroundImage: `url(${principal && principal?.hero})` }}>

                                <div className="featured--vertical">
                                    <div className="featured--horizontal">
                                        <div className="featured--name">
                                            <img className="featured--lettering" src={principal && principal?.title_lettering} alt={principal && principal?.title} />
                                        </div>

                                        <div className="featured--description"> {principal && principal?.description?.substr(0, 200)} ...</div>
                                        <div className="featured--buttons">
                                            <span
                                                onClick={() => {
                                                    setSelected(principal)
                                                    location(`/adventure/${selected.slug}`)
                                                }}
                                                className="featured--watchbutton"
                                                data-dismiss="modal"
                                            >🎲 Jogar</span>
                                            <span
                                                onClick={() => {
                                                    setSelected(principal)
                                                }}
                                                className="featured--watchbutton"
                                                data-dismiss="modal"
                                                data-toggle="modal"
                                                data-target="#modal--viewer-filme"
                                            >📂 Saber Mais</span>
                                        </div>


                                    </div>
                                </div>

                            </section>
                        </div>
                    }
                </div>

            </div>
        </>
    );

}