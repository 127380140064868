import React from "react";
import './Footer.css';
import SocialButtons from "../Buttons/discord/socialButtons";

export default () => {


  const aboutUsText = `
    Guilda da Abóbora é uma iniciativa da Pumpkin Studio, um estúdio de jogos analógicos e digitais independente brasileiro. 
    Nossa missão é criar e entregar os melhores e mais engajados jogos contendo histórias fantasticas, mundos inteiros para explorar e muita aventura.
    
    Nossos conteúdos contam com a presença ilustre da nossa comunidade, a Guilda da Abóbora, que auxilia diretamente na sua produção, seja com dicas,
    acompanhamentos e rodadas de votações.
    `;

  return (

    <>
      <SocialButtons />
      <footer className="site-footer">
        <div className="footer--vertical">
          <div className="container footer-container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <h6>Sobre Nós</h6>
                <p className="text-justify">{aboutUsText}</p>

              </div>
              {/*}
              <div className="col-xs-6 col-md-3">
                <h6>Categories</h6>
                <ul className="footer-links">
                  <li><a href="http://scanfcode.com/category/c-language/">C</a></li>
                  <li><a href="http://scanfcode.com/category/front-end-development/">UI Design</a></li>
                  <li><a href="http://scanfcode.com/category/back-end-development/">PHP</a></li>
                  <li><a href="http://scanfcode.com/category/java-programming-language/">Java</a></li>
                  <li><a href="http://scanfcode.com/category/android/">Android</a></li>
                  <li><a href="http://scanfcode.com/category/templates/">Templates</a></li>
                </ul>
              </div>
    
              <div className="col-xs-6 col-md-3">
                <h6>Quick Links</h6>
                <ul className="footer-links">
                  <li><a href="http://scanfcode.com/about/">About Us</a></li>
                  <li><a href="http://scanfcode.com/contact/">Contact Us</a></li>
                  <li><a href="http://scanfcode.com/contribute-at-scanfcode/">Contribute</a></li>
                  <li><a href="http://scanfcode.com/privacy-policy/">Privacy Policy</a></li>
                  <li><a href="http://scanfcode.com/sitemap/">Sitemap</a></li>
                </ul>
              </div>
              */}
            </div>
            <hr />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <p className="copyright-text">Copyright &copy; 2019 All Rights Reserved by Pumpkin Studio.
                </p>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <ul className="social-icons">
                  <li><a className="facebook" href="https://www.facebook.com/pumpkinoficial"><i className="fa fa-facebook"></i></a></li>
                  <li><a className="twitter" href="https://twitter.com/pumpkinstudiobr"><i className="fa fa-twitter"></i></a></li>
                  <li><a className="instagram" href="https://instagram.com/pumpkinstudiobr"><i className="fa fa-instagram"></i></a></li>
                  <li><a className="linkedin" href="https://www.linkedin.com/company/pumpkinstudio"><i className="fa fa-linkedin"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}