import React from "react";
import { Link } from "react-router-dom";
import './guild-landing-page.css';

export const GuildLandingPage = () => {

    const dataInsert = [
        {
            title: "Bestiário Completo",
            image: `https://pumpkinstudio.com.br/wp-content/uploads/2021/08/imagem-conceitual-recompensas-Pumpkin-Studio.png`,
            text: `Libere o acesso completo a uma variedade incrível de monstros no bestiário da Guilda, disponíveis para
            você e seu grupo no app da Guilda da Abóbora.`
        },
        {
            title: "Mapas, Itens e Extras",
            image: `https://pumpkinstudio.com.br/wp-content/uploads/2021/08/Mais-imagens-conceituais.png`,
            text: `Tenha acesso a mapas para usar nas suas campanhas, além de itens e muitos outros bônus.
            Fazendo parte da Guilda, você ainda garante a produção de muitos outros materiais extras todos os meses.`
        },
        {
            title: "Descontos Fantásticos",
            image: `https://pumpkinstudio.com.br/wp-content/uploads/2021/10/SIstema-Facil-e-Revista.png`,
            text: `Membros da Guilda recebem inúmeros descontos para adquirir produtos lançados no passado, garantindo as
            melhores condições para não perder nada do que já foi feito na Guilda.`
        },
    ]

    return (
        <div className="landing-page background-black">

            <div className="landing-page-hero ">
                <div className="landing-page-hero-vertical header-padding">
                    <div className="landing-page-hero-section-one">
                        <div className="col-lg-6 landing-page-hero-section-two flex-column justify-content-center">
                            <h1>Faça parte de uma Guilda criada para quem ama mundos fantásticos</h1>
                            <span>Tenha acesso a um bestiário incrível, mapas, itens e um incrível mundo de fantasia em um único lugar!</span>
                            <div>
                                <div className="featured--buttons">
                                    <Link to='/signature'>
                                        <span className="featured--watchbutton button-guild"> Entrar para a Guilda 🎃</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <img src={`https://pumpkinstudio.com.br/wp-content/uploads/2021/10/Guilda-da-abobora.png`} />
                    </div>
                    <div className=""></div>
                </div>
            </div>

            <div className={`guild-advantage row nowrap row-cols-1 row-cols-md-${dataInsert?.length || 3}  g-4 nowrap`}>
                {dataInsert.map(card => (
                    <div className="col col-promo-card">
                        <div className="card promo-card">
                            <img src={card?.image} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">{card?.title}</h5>
                                <p className="card-text">{card?.text}</p>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <div>
                {
                    /* <div className="landing-page-next">
                        <div className="">
                            <div className="landing-page-hero-section-one">
                                <div className="col-lg-6 landing-page-hero-section-two flex-column justify-content-center">
                                    <h1 className="color-black">Faça parte de uma Guilda criada para quem ama mundos fantásticos</h1>
                                    <span className="color-black">Tenha acesso a um bestiário incrível, mapas, itens e um incrível mundo de fantasia em um único lugar!</span>
                                    
                                </div>
                                <img className="mobile-disable" src={`https://pumpkinstudio.com.br/wp-content/uploads/2020/11/11-Novembro-2020-0005-Landing-Page-Images_Monsterslitch.png`} />
                            </div>
                            <div className=""></div>
                        </div>
                    </div> */
                }
            </div>

            <div className="col">
                {/*
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Accordion Item #1
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Accordion Item #2
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Accordion Item #3
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                </div>
                */
                }
            </div>



        </div>
    )
}