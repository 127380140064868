import React, { useContext } from "react";
import { ProductContext } from '../../Contexts/product-context';
import './Materiais.css'
import { useNavigate } from "react-router-dom";

export const Materiais = () => {

    const { selected } = useContext(ProductContext);

    return (
        <div className="container-fluid relative animatedParent animateOnce p-0 background-black">
            <div className="wrapper p-3 p-lg-5">
            <div className="animated fadeInUpShort">
                <div className="row my-5 pt-5">
                    <div className="col-md-3">
                        <img src={selected?.thumb} className="productRow--item" alt="/" />
                    </div>
                    <div className="col-md-9">
                        <div className="d-md-flex align-items-center justify-content-between">
                            <h1 className="my-3 text-white ">{selected?.title}</h1>
                        </div>
                        <div className="text-white my-2">
                            <p>{selected?.description}</p>
                        </div>
                    </div>
                </div>
            </div>

                <section>
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="playlist">
                                        <ul className="list-group">
                                            <li className="list-group-item my-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="col-6">
                                                        <h6>Extras</h6>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <a href={selected?.complements} download className="btn btn-outline-primary btn-sm">
                                                            Download
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>

        </div>
    )
}