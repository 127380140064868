import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import AppRoutes from './routes';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from "./styles/toast";
import UserProvider from './Contexts/user-context';
import ProductProvider from './Contexts/product-context';
import CartProvider from './Contexts/cart-context';
import SignatureProvider from './Contexts/signature-context';
import YoutubeProvider from './Contexts/youtube-context';
import WordpressProvider from './Contexts/wordpress-context';
import MonsterProvider from './Contexts/monster-context';
import RaceProvider from './Contexts/race-context';


const contexts = [
  { ContextProvider: UserProvider, props: {} },
  { ContextProvider: ProductProvider, props: {} },
  { ContextProvider: CartProvider, props: {} },
  { ContextProvider: SignatureProvider, props: {} },
  { ContextProvider: YoutubeProvider, props: {} },
  { ContextProvider: WordpressProvider, props: {} },
  { ContextProvider: MonsterProvider, props: {} },
  { ContextProvider: RaceProvider, props: {} },
];

const ContextProviders = ({ children }) => {
  return contexts.reduceRight((acc, { ContextProvider, props }) => {
    return (
      <ContextProvider {...props}>
        {acc}
      </ContextProvider>
    );
  }, children);
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ToastContainer />
    <ContextProviders>
      <AppRoutes />
    </ContextProviders>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
