import React, { useState, createContext, useEffect } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const MonsterContext = createContext();

const MonsterProvider = ({ children }) => {

    //PEGAR DADOS DO CARRINHO

    const [monsters, setMonsters] = useState();
    const [selectedMonster, setSelectedMonster] = useState();
    const [paidMonsters, setPaidMonsters] = useState();

    const getMonsters = async () => {
        try {
            const response = await api.get('/monster');
            const res = response.data;

            if (res.monsters === null) { return }

            setMonsters(res);    

        } catch (err) {
            toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message);
        }
    };    

    const getPaidMonsters = async () => {
        try {
            const response = await api.get('/monster/paid');
            const res = response.data;
            
            setPaidMonsters(res);    

        } catch (err) {
            toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message);
        }
    };    

    return (
        <MonsterContext.Provider value={{ monsters, setMonsters, paidMonsters, selectedMonster, getMonsters, getPaidMonsters, setSelectedMonster }}>
            {children}
        </MonsterContext.Provider>
    )

};

export default MonsterProvider;