import React, { useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import './races.css';
import { RaceContext } from '../../Contexts/race-context';



export function Races() {

    const { races, selectedRace, getRaces, setSelectedRace } = useContext(RaceContext);
    const { slug } = useParams()

    useEffect(() => {
        if (!races) {
            getRaces()
        }
    }, [])

    const selectRace = () => {
        if (!selectedRace && races) {
            const filterRace = races.filter(element => element.slug === slug)
            setSelectedRace(filterRace[0])
        }
    }

    useEffect(() => {
        selectRace()
    }, [races, selectedRace])

    return (
        <>
            <div id="races-hero" style={{
                backgroundImage: `url(${selectedRace ? selectedRace.background_image : ''})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center bottom',
                backgroundSize: 'cover',
                height: '50%'
            }}>
                <img src={selectedRace?.image || ''} alt="Overlay" style={{
                    position: 'absolute',
                    top: '10%',
                    right: '20%',
                    maxWidth: '75%',
                    height: '75%',
                }} />

            </div>
            <div id="races-content">
                <div className="container">
                    <section className="first-section">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="monster-name race-title">
                                    {selectedRace?.name}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <p className="p-races mote">{`— ${selectedRace?.mote}`}</p>
                                <p className="p-races">{selectedRace?.initialDescription}</p>

                                {selectedRace?.first_box_info?.map((item, index) => {
                                    // Encontra a posição do primeiro ponto na string
                                    const firstDotIndex = item.indexOf('.');

                                    // Decide se o item deve ser um h2 ou p
                                    return firstDotIndex !== -1 && firstDotIndex < item.length - 1 ? (
                                        <div key={index}>
                                            <h3 style={{ color: 'white', paddingTop: '15px' }}>{item.substring(0, firstDotIndex)}</h3>
                                            <p className="p-races">{item.substring(firstDotIndex + 2)}</p>
                                        </div>
                                    ) : (
                                        <p className="p-races" key={index}>{item}</p>
                                    );
                                })}
                            </div>

                            <div className="col-sm-6">
                                <h3 style={{ color: 'white', paddingTop: '15px' }}>{`Nomes de ${selectedRace?.name}`}</h3>

                                <p className="p-races">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {`Masculinos: `}
                                    </span>
                                    {selectedRace?.names?.male?.join(', ')}
                                </p>
                                <p className="p-races">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {`Femininos: `}
                                    </span>
                                    {selectedRace?.names?.female?.join(', ')}
                                </p>
                                <p className="p-races">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {`Sobrenomes: `}
                                    </span>
                                    {selectedRace?.names?.surname?.join(', ')}
                                </p>

                                <h3 style={{ color: 'white', paddingTop: '15px' }}>{`Traços Raciais`}</h3>

                                {selectedRace?.second_box_info?.map((item, index) => {
                                    const firstDotIndex = item.indexOf('.');
                                    return firstDotIndex !== -1 && firstDotIndex < item.length - 1 ? (
                                        <div key={index}>

                                            <p className="p-races">
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {item.substring(0, firstDotIndex + 1)}
                                                </span> {' '}
                                                {item.substring(firstDotIndex + 2)}
                                            </p>
                                        </div>
                                    ) : (
                                        <p className="p-races" key={index}>{item}</p>
                                    );
                                })}
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </>
    );
}
