import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api';
import './Login.css';
import { capitalizeEachWords } from '../../helpers/capitalize-string';
import { SignatureContext } from '../../Contexts/signature-context';

function Cadastro() {

    const { plan } = useContext(SignatureContext);

    const navigate = useNavigate();

    const [cadastro, setCadastro] = useState(
        {
            name: '',
            surname: '',
            email: '',
            password: ''
        }
    );

    const [isLoading, setIsLoading] = useState(false)

    const cadastrando = async () => {

        window.addEventListener('submit', function (e) { e.preventDefault() })
        const btnCad = document.querySelector('.cad_btn');

        setIsLoading(true)

        const validateCadFields = document.querySelectorAll('#frm-signup :invalid').length;
        if (validateCadFields !== 0) { return false }

        await api.post('customer', cadastro)
            .then(response => {
                
                setIsLoading(false)
                
                toast.success("Usuário cadastrado com sucesso. Cheque seu e-mail");

                let getToken = JSON.stringify(response.data.token);
                let getUserInfo = JSON.stringify(response.data.data);
                localStorage.setItem('@user', JSON.parse(getToken));
                localStorage.setItem('@userInfo', getUserInfo);

                if (plan) {
                    navigate('/signature-checkout')
                }

                else {
                    navigate('/')
                }


            })
            .catch((reason => {
                toast.warn(reason.message);
                setIsLoading(false)
            }));
    };

    return (

        <div className="container-login-page">
            <div className="frm-main-container">
                <div className="inner">
                    <div className="frm-change-links">
                        <span onClick={() => { navigate('/login') }} id="signin-btn">ENTRAR</span>
                        <span onClick={() => { navigate('/signup') }} className="active" id="signup-btn">REGISTRAR</span>
                    </div>

                    {/* Cadastro */}

                    <form action="" className="frm-signup" id="frm-signup">

                        <div className="frm-group">
                            <label htmlFor="fname">Informações</label>

                            <div className="cad_fones">
                                <input onChange={(e) => {
                                    setCadastro({ ...cadastro, name: capitalizeEachWords(e.target.value.trim()) });
                                }}
                                    type="text"
                                    id="cad_name"
                                    className="form-control form-control-lg"
                                    placeholder="Nome"
                                    required
                                    autoComplete="off" />

                                <input onChange={(e) => {
                                    setCadastro({ ...cadastro, surname: capitalizeEachWords(e.target.value.trim()) });
                                }}
                                    type="text"
                                    id="cad_lname"
                                    className="form-control form-control-lg"
                                    placeholder="Sobrenome"
                                    required
                                    autoComplete="off"
                                />
                            </div>

                        </div>

                        {/*<div className="frm-group">
          <label htmlFor="lname">Sobrenome</label>
          <input onChange={(e) => {
              setCadastro({ ...cadastro, lastName: e.target.value });
            }}
            type="text" 
            id="cad_lname"
            className="form-control form-control-lg"
            placeholder="Sobrenome"
            required
            />
          </div> */}

                        {/*<div className="frm-group">
          <label htmlFor="surname">Nome Social</label>
          <input onChange={(e) => {setCadastro({ ...cadastro, surname: e.target.value });
            }}
            type="text" 
            id="cad_surname"
            className="form-control form-control-lg"
            placeholder="Quer ser chamado(a) como?"
            />
          </div> */}

                        {/*<div className="frm-group">
          <label htmlFor="text">Telefone</label>
          <div className="cad_fones">
          <input onChange={(e) => {
              setCadastro({ ...cadastro, ddi: e.target.value });
            }}
            type="text" 
            id="cad_ddifone"
            className="form-control form-control-lg"
            placeholder="DDI"
            minLength="2"
            maxLength="2" 
            size="2"
            required
            />
            
          <input onChange={(e) => {
              setCadastro({ ...cadastro, phoneNumber: e.target.value });
            }}
            type="text" 
            id="cad_fone"
            className="form-control form-control-lg"
            placeholder="DDD + Telefone"
            required
            />
            </div>
          </div>*/}

                        <div className="frm-group">
                            <label>e-mail</label>
                            <input onChange={(e) => {
                                setCadastro({ ...cadastro, email: e.target.value.trim().toLowerCase() });
                            }}
                                type="email"
                                className="form-control form-control-lg"
                                placeholder="E-mail"
                                required
                                autoComplete="off"
                            />
                        </div>

                        {/*<div className="frm-group">
          <label>CPF</label>
            <input onChange={(e) => {
              setCadastro({ ...cadastro, identificationDocument: e.target.value });
            }}
            type="text"
            className="form-control form-control-lg"
            placeholder="CPF"
            inputmode="number" 
            minLength="11" 
            maxLength="11"
            pattern="^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}"
            required 
            autoComplete="off" />
          </div>*/}

                        <div className="frm-group" id='toCadPass'>

                            <label htmlFor="password">Senha</label>

                            <input onChange={(e) => {
                                setCadastro({ ...cadastro, password: e.target.value })

                                //const igualFirsName = cadastro.firstName;
                                //if (cadastro.surname === '') { setCadastro({ ...cadastro, surname: igualFirsName }) };
                                //if (cadastro.ddi === 1) { setCadastro({ ...cadastro, ddi: 55 }) };
                            }}
                                type="password"
                                id='cadPass'
                                className="form-control form-control-lg"
                                placeholder="Senha"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                title="Sua senha deve conter no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma minúscula e um número."
                                size="36"
                                required
                                autoComplete="off"
                            />
                            <span type="button" className="btn-show-pass" id='cad_pass' onClick={() => {
                                const passInputcad = document.getElementById('cadPass')
                                const cadEye = document.querySelector('#toCadPass .fa')
                                if (passInputcad.type === 'password') {
                                    cadEye.classList.remove('fa-eye-slash')
                                    passInputcad.type = 'text'
                                }
                                else {
                                    cadEye.classList.add('fa-eye-slash')
                                    passInputcad.type = 'password'
                                }
                            }}>
                                <i id="eyeIcon" className="fa fa-eye fa-eye-slash"></i>
                            </span>

                        </div>

                        {/* <div className="frm-group">
          <label htmlFor="password">Repita a Senha</label>
          <input 
            type="password" 
            id="password"
            className="form-control form-control-lg"
            placeholder="Senha"  />
        </div> */}

                        <div className="frm-group">
                            <br />
                            {!isLoading && <button onClick={cadastrando} className="cad_btn" id="cad_btn" type="submit" >CRIAR CONTA</button>}
                            {isLoading && <button disabled className="loadingBtn" type="submit" >{'REGISTRANDO '}</button>}
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
}

export default Cadastro;
