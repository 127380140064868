import React, { useState, useEffect, useContext } from "react";
import {useParams} from "react-router-dom";
import '../../components/Hero/Hero.css';
import './blog-page.css'

import { BookLoader } from "../../components/utils/BookLoader/BookLoader";

import { WordpressContext } from "../../Contexts/wordpress-context";

export default ({ }) => {

    const { materias, materiaSelecionada, setMateriaSelecionada, getBlog } = useContext(WordpressContext);
    const { slug } = useParams()

    useEffect(()=>{
        getBlog()              
    }, [])

    const selectMateria = () => {
        if (!materiaSelecionada && materias) {            
            const filterMateria = materias.filter( element => element.slug === slug )          
            setMateriaSelecionada(filterMateria[0])
        }
    }

    useEffect(()=>{
        selectMateria()               
    }, [materias, materiaSelecionada])


    return (
        <>
        {!materiaSelecionada && <BookLoader />}
            {
                materiaSelecionada &&
                materiaSelecionada._embedded &&
                materiaSelecionada._embedded['wp:featuredmedia'][0].source_url &&

                <section className="featured-blog" style={{ backgroundImage: `url(${materiaSelecionada._embedded['wp:featuredmedia'][0].source_url})` }}>

                    <div className="featured-vertical-blog">                        
                            <div className="hero-blog-title-div">
                                <h1 className="hero-blog-title"> {materiaSelecionada.title.rendered} </h1>
                            </div>                 
                    </div>

                </section>
            }

            {
                materiaSelecionada &&
                materiaSelecionada._embedded &&
                materiaSelecionada._embedded['wp:featuredmedia'][0].source_url &&
                <div className="p-3 blog-text background-black">
                    <div className="col-lg-8 offset-lg-2 p-3">                        

                        <article>

                                <div 
                                className="card-bodyz my-5" 
                                dangerouslySetInnerHTML={{ __html: (materiaSelecionada.content.rendered).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '') }}
                                >
                                </div>
                           
                        </article>


                    </div>
                </div>
            }
            
        </>
    );

}