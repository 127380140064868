import React from "react";

export const NotFound = () => {

    return (
        <div className="d-flex align-items-center justify-content-center"
            style={{
                height: 60 + 'vh'
            }}
        >
            <h1 className="display-1 fw-bold text-white"
                style={{
                    fontSize: 150 + 'px',
                    marginBottom: 0+'px'
                }}
            >404</h1>
        </div>
    )
}