import React, { useContext, useEffect } from "react";
import {useParams, useNavigate} from "react-router-dom";

import { YoutubeContext } from "../../Contexts/youtube-context";
import './video-single.css';

const VideoSingle = () => {

    const location = useNavigate();
    const { id } = useParams()

    const { 
        videos, 
        selectedVideo, 
        setSelectedVideo,
        //getVideos
    } = useContext(YoutubeContext);
    

    useEffect(() => {
        //getVideos()
    }, [selectedVideo])

    const selectVideo = () => {
        if (!selectedVideo && videos) {            
            const filterVideo = videos.items.filter( element => element.id.videoId === id )          
            setSelectedVideo(filterVideo[0])            
        }
    }

    useEffect(()=>{
        selectVideo()               
    }, [videos, selectedVideo])

    return (
        <div className="background-black header-padding">
            <div className="container-fluid relative animatedParent animateOnce">
                <div className="wrapper lateral-padding ">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="video-responsive">
                                <div className="card no-b r-0">
                                    <div className="card-body">
                                        <div className="d-lg-flex align-items-center">
                                            <h1 className="my-3 h4">{selectedVideo && selectedVideo?.snippet?.title}</h1>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item"
                                        src={selectedVideo && `https://www.youtube.com/embed/${selectedVideo?.id?.videoId}?rel=0`}
                                        height="540"
                                        frameBorder="0"
                                        allowFullScreen
                                        title="embed-video-iframe"                                     
                                    >
                                    </iframe>
                                </div>

                            </div>
                            {/*
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="lightSlider has-items-overlay playlist"
                                    data-item="3"
                                    data-item-xl="3"
                                    data-item-lg="3"
                                    data-item-md="3"
                                    data-item-sm="1"
                                    data-auto="false"
                                    data-pager="false"
                                    data-controls="true"
                                    data-loop="false">
                                    <div>
                                        <div className="p-5 bg-primary text-white">
                                            <h5 className="font-weight-normal s-14">Views</h5>
                                            <span className="s-48 font-weight-lighter text-primary">140</span>
                                            <div> Likes
                                                <span className="text-primary">
                                                    <i className="icon icon-arrow_downward"></i> 67%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="p-5">
                                            <h5 className="font-weight-normal s-14">Share</h5>
                                            <span className="s-48 font-weight-lighter amber-text">700</span>
                                            <div>
                                                <span className="amber-text">
                                                    <i className="icon icon-arrow_downward"></i> 34</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="p-5 light">
                                            <h5 className="font-weight-normal s-14">Comments</h5>
                                            <span className="s-48 font-weight-lighter text-indigo">411</span>
                                            <div> Iron
                                                <span className="text-indigo">
                                                    <i className="icon icon-arrow_downward"></i> 89%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="p-5">
                                            <h5 className="font-weight-normal s-14">Likes</h5>
                                            <span className="s-48 font-weight-lighter pink-text">224</span>
                                            <div> Sodium
                                                <span className="pink-text">
                                                    <i className="icon icon-arrow_downward"></i> 47%</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="card mt-1 mb-5">
                            <div className="card-body">
                                <div className="media my-5 ">
                                    <div className="avatar avatar-md mr-3 mt-1">
                                        <img src="assets/img/demo/u7.png" alt="user" />
                                    </div>
                                    <div className="media-body">
                                        <h6 className="mt-0">Ami Fro</h6>
                                        Cras sit amet nibh libero, in gravida nulla.
                                    </div>
                                </div>
                                <div className="media my-5 ">
                                    <div className="avatar avatar-md mr-3 mt-1">
                                        <img src="assets/img/demo/u6.png" alt="user" />
                                    </div>
                                    <div className="media-body">
                                        <h6 className="mt-0">Sara Kamzoon</h6>
                                        vestibulum in vulputate at, tempus viverra turpis.
                                        <div className="media p-2 my-4">
                                            <a href="#">
                                                <div className="avatar avatar-md mr-3 mt-1">
                                                    <img src="assets/img/demo/u8.png" alt="user" />
                                                </div>
                                            </a>
                                            <div className="media-body">
                                                <h6 className="mt-0">Joe Doe</h6>
                                                Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                                                sollicitudin.
                                            </div>
                                        </div>
                                        <div className="media p-2 my-4">
                                            <a href="#">
                                                <div className="avatar avatar-md mr-3 mt-1">
                                                    <img src="assets/img/demo/u7.png" alt="user" />
                                                </div>
                                            </a>
                                            <div className="media-body">
                                                <h6 className="mt-0">Find Doe

                                                </h6>
                                                Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                                                sollicitudin.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="media my-5 ">
                                    <div className="avatar avatar-md mr-3 mt-1">
                                        <img src="assets/img/demo/u9.png" alt="user" />
                                    </div>

                                    <div className="media-body">
                                        <h6 className="mt-0">Ami Fro</h6>
                                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                                        sollicitudin. Cras purus odio, vestibulum in
                                        vulputate at, tempus viverra turpis.
                                    </div>
                                </div>
                                <div className="media my-5 ">
                                    <div className="avatar avatar-md mr-3 mt-1">
                                        <img src="assets/img/demo/u10.png" alt="user" />
                                    </div>
                                    <div className="media-body">
                                        <h6 className="mt-0">Ami Fro </h6>
                                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                                        sollicitudin. Cras purus odio, vestibulum in
                                        vulputate at, tempus viverra turpis.
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}
                        </div>
                        <div className="col-lg-4">
                            <div className="relacionados card pt-3">
                                <div className="card-header">
                                    <h6 className="card-header-title">Vídeos Relacionados</h6>
                                </div>
                                <div className="card-relacionados">
                                    {videos && videos?.items && videos?.items.slice(0, 5).map((video) => (
                                        <div className="clicable-video d-flex align-items-center mb-4 "
                                        key={video.id.videoId}
                                            onClick={() => {
                                                setSelectedVideo(video)
                                                location(`/video/${video.id.videoId}`)
                                            }}
                                        >
                                            <div className="col-5">
                                                <img className="thumbImg" src={video.snippet.thumbnails.default.url} alt="card" />
                                            </div>
                                            <div className="ml-3 title-relacionados">                                                
                                                    <h6>{video.snippet.title}</h6>                                                
                                                <small className="mt-1">Pumpkin Studio</small>
                                            </div>
                                        </div>
                                    ))}


                                </div>
                            </div>
                            {/* <div className="my-4">
                            <div className="d-flex align-items-center mb-4 ">
                                <div className="col-5">
                                    <img src="assets/img/demo/v5.jpg" alt="Card image" />
                                </div>
                                <div className="ml-3">
                                    <a href="video-single.html">
                                        <h6>Ilallah, Sounds of Kolachi, Record Studio Season</h6>
                                    </a>
                                    <small className="mt-1">Record Studio</small>
                                </div>
                            </div>
                        </div> */}



                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default VideoSingle;