import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import './monster-page.css'

import { MonsterContext } from "../../Contexts/monster-context";

//import Header from "../../components/Header";

const MonsterPage = () => {

    const { monsters, selectedMonster, setSelectedMonster, getMonsters } = useContext(MonsterContext);
    const { slug } = useParams()

    useEffect(() => {
        getMonsters()
    }, [])

    const selectMonster = () => {
        if (!selectedMonster && monsters) {
            const filterMonster = monsters.filter(element => element.slug === slug)
            setSelectedMonster(filterMonster[0])
        }
    }

    useEffect(() => {
        selectMonster()
    }, [monsters, selectedMonster])

    const renderProperty = (label, value) => {

        if (value && ((Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value.trim() !== ''))) {
            return (
                <div className="font-bold">
                    {label}: <span>{Array.isArray(value) ? value[0] : value}</span>
                </div>
            );
        }

        return null; // Retorna null se o array estiver vazio ou a string estiver vazia
    };

    const firstBoxInfo = selectedMonster?.first_box_info || {};
    const {
        testes_de_resistencia,
        pericias,
        vulnerabilidade_a_dano,
        resistencia_a_dano,
        imunidade_a_dano,
        imunidade_a_condicao,
        sentidos,
        idiomas,
        nd,
        experience
    } = firstBoxInfo;

    const calc = (param) => {
        switch (param) {
            case 1:
                return "-5";
            case 2:
                return "-4";
            case 3:
                return "-4";
            case 4:
                return "-3";
            case 5:
                return "-3";
            case 6:
                return "-2";
            case 7:
                return "-2";
            case 8:
                return "-1";
            case 9:
                return "-1";
            case 10:
                return "0";
            case 11:
                return "0";
            case 12:
                return "+1";
            case 13:
                return "+1";
            case 14:
                return "+2";
            case 15:
                return "+2";
            case 16:
                return "+3";
            case 17:
                return "+3";
            case 18:
                return "+4";
            case 19:
                return "+4";
            case 20:
                return "+5";
            case 21:
                return "+5";
            case 22:
                return "+6";
            case 23:
                return "+6";
            case 24:
                return "+7";
            case 25:
                return "+7";
            case 26:
                return "+8";
            case 27:
                return "+8";
            case 28:
                return "+9";
            case 29:
                return "+9";
            case 30:
                return "+10";
        }
    }

    const printing = (element) => {
        const replaced = element.replace(/<br>/g, '\n\n'); // Duas novas linhas
        const splited = replaced.split('.');
        splited.pop();
        const filtered = splited.filter((element, index) => {
            return (index !== 0)
        });
        const mapped = filtered.map((strings) => {
            return (strings + ".");
        });

        return mapped;
    };

    return (
        <>

            <div className="header-padding monster-page">

                <div className="monster-principal-info index-z-3">
                    <div className="monster-name">
                        {`${selectedMonster?.name}`}
                    </div>
                    <div className="monster-tendencia">
                        {`${selectedMonster?.specie} ${selectedMonster?.size}, ${selectedMonster?.alignment} `}
                    </div>
                </div>

                <div className="mg-btm-30-cento monster-status index-z-3">
                    {selectedMonster?.status && Object.entries(selectedMonster.status).map(([key, value], index) => (
                        <div className="monster-status-infos" key={index}>
                            <span> {key} </span>
                            <div className="monster-status-infos-first">
                                <span> {value} </span>
                                <div className="monster-status-infos-second">
                                    <span>
                                        {calc(value)}
                                    </span>
                                </div>
                                <div className="monster-status-infos-third">
                                </div>
                            </div>
                        </div>
                    ))}



                </div>


                <div className="engloba-tudo engloba-texto-imagem-ficha reverse-row-on-mobile">
                    <div className="col-sm-4">
                        <div className="secundary-monster-name index-z-3">
                            <span>{`${selectedMonster?.name}`}</span>
                        </div>
                        <div className="monster-text index-z-3">
                            {selectedMonster?.description && selectedMonster?.description.split("<br>").map((paragraph, index) => (
                                <p className="index-z-3" key={index}>{paragraph}</p>
                            ))}
                        </div>
                    </div>

                    <div className="col-sm index-z-2">
                        <div className="engloba-imagem-ficha">
                            <div className="engloba-tudo monster-image">
                                <img className="col-sm" src={selectedMonster?.image} />
                                <div className="col-sm monster-ficha index-z-3">

                                    <div className="monster-principal-info ">
                                        <div className="monster-name">
                                            {`${selectedMonster?.name}`}
                                        </div>
                                        <div className="monster-tendencia">
                                            {`${selectedMonster?.specie} ${selectedMonster?.size}, ${selectedMonster?.alignment} `}
                                        </div>
                                    </div>
                                    <div className='ficha-hr'></div>
                                    <div className="font-bold">Classe de Armadura: <span>{selectedMonster?.armor_class} {`(${selectedMonster?.armor_class_extra_info})`}</span></div>
                                    <div className="font-bold">Pontos de Vida: <span>{selectedMonster?.life_points} {selectedMonster?.life_points_dices}</span></div>
                                    <div className="font-bold">Deslocamento: <span>{selectedMonster?.movement}</span></div>

                                    <div className='ficha-hr'></div>

                                    <div className="monster-status sp-btwn">
                                        {selectedMonster?.status && Object.entries(selectedMonster.status).map(([key, value], index) => (
                                            <div className="monster-status-infos" key={index}>
                                                <span > {key} </span>
                                                <div className="monster-status-infos-first">
                                                    <span> {value} </span>
                                                    <div className="monster-status-infos-second">
                                                        <span>
                                                            {calc(value)}
                                                        </span>
                                                    </div>
                                                    <div className="monster-status-infos-third">
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='ficha-hr'></div>

                                    {renderProperty('Testes de Resistência', testes_de_resistencia)}
                                    {renderProperty('Perícias', pericias)}
                                    {renderProperty('Vulnerabilidade a Dano', vulnerabilidade_a_dano)}
                                    {renderProperty('Resistência a Dano', resistencia_a_dano)}
                                    {renderProperty('Imunidade a Dano', imunidade_a_dano)}
                                    {renderProperty('Imunidade a Condição', imunidade_a_condicao)}
                                    {renderProperty('Sentidos', sentidos)}
                                    {renderProperty('Idiomas', idiomas?.length ? idiomas : "--")}
                                    {nd && (
                                        <div className="font-bold">
                                            Nível do Desafio: <span>{nd} {`(${experience} XP)`}</span>
                                        </div>
                                    )}

                                    <div className='ficha-hr'></div>

                                    {selectedMonster?.second_box_info && selectedMonster?.second_box_info.map((value, index) => (
                                        <div className="btm-pding-5">
                                            <span className="font-bold" key={index}>{`${value.split('.', 1)[0]}. `}</span>
                                            <span>{printing(value)}</span>
                                        </div>
                                    ))}

                                    {selectedMonster?.actions?.length > 0 &&

                                        <div >
                                            <span className="ficha-actions">AÇÕES</span>
                                            <div className='ficha-hr'></div>
                                        </div>
                                    }

                                    {selectedMonster?.actions && selectedMonster?.actions.map((value, index) => (
                                        <div className="btm-pding-5">
                                            <span className="font-bold" key={index}>{`${value.split('.', 1)[0]}. `}</span>
                                            <span>{printing(value)}</span>
                                        </div>
                                    ))}

                                    {selectedMonster?.legendary_actions?.length > 0 &&
                                        <div >
                                            <span className="ficha-actions">AÇÕES LENDÁRIAS</span>
                                            <div className='ficha-hr'></div>
                                        </div>
                                    }

                                    {selectedMonster?.legendary_actions && selectedMonster?.legendary_actions.map((value, index) => (
                                        <div className="btm-pding-5">
                                            <span className="font-bold" key={index}>{`${value.split('.', 1)[0]}. `}</span>
                                            <span>{printing(value)}</span>
                                        </div>
                                    ))}

                                    {selectedMonster?.reactions?.length > 0 &&
                                        <div >
                                            <span className="ficha-actions">REAÇÕES</span>
                                            <div className='ficha-hr'></div>
                                        </div>
                                    }

                                    {selectedMonster?.reactions && selectedMonster?.reactions.map((value, index) => (
                                        <div className="btm-pding-5">
                                            <span className="font-bold" key={index}>{`${value.split('.', 1)[0]}. `}</span>
                                            <span>{printing(value)}</span>
                                        </div>
                                    ))}

                                </div>
                            </div>


                        </div>
                    </div>


                </div>





            </div>

        </>
    );

}

export default MonsterPage;