import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import './Header.css'

import Carrinho from '../Carrinho'
import HeaderLogo from "./HeaderComponents/HeaderLogo";
import Account from "./HeaderComponents/Account";
import { UserName } from "./HeaderComponents/UserName";
import PromoHeader from "../PromoHeader/PromoHeader";

const Header = React.memo(function Header() {

    const pathLocation = useLocation()
    const user = localStorage.getItem('@user');

    /* const [blackHeader, setBlackHeader] = useState(false);

    const scrollListner = () => {
        if (window.scrollY > 10) { setBlackHeader(true) } else { setBlackHeader(false) }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollListner);
        return () => {
            window.removeEventListener('scroll', scrollListner);
        }
    }, []); */


    return (
        <>
            {pathLocation?.pathname === "/" && <PromoHeader />}
            <header className={/* blackHeader ?  *//* 'black' : */ ''}>
                <HeaderLogo />

                {user &&
                    <div className="header--rigthbox">
                        {
                            <UserName />
                        }
                        {pathLocation?.pathname === "/" && <Carrinho />}
                        <Account />
                    </div>
                }
            </header>
        </>
    )
})

export default Header;