import React, { useState, useEffect, useContext } from "react";
import { ProductContext } from "../../Contexts/product-context";


export default () => {

    const { collection, setSelected, getCollections } = useContext(ProductContext);

    const [scrollX, setScrollX] = useState(0);
    const [newScreen, setNewScreen] = useState(220);

    const theSize = () => {
        let screenSize = window.screen.width;
        let widthList;
        if (screenSize < 760) { widthList = 170 } else { widthList = 260 }
        setNewScreen(widthList);
    }

    useEffect(() => {
        theSize();
        return
    }, []);

    useEffect(() => {
        getCollections();
    }, []);

    const handleLeftArrow = () => {
        let x = scrollX + Math.round(window.innerWidth / 2);
        if (x > 0) { x = 0; } setScrollX(x);
    }
    const handleRigthArrow = () => {
        let x = scrollX - Math.round(window.innerWidth / 2);
        let listW = collection.length * newScreen;
        if ((window.innerWidth - listW) > x) { x = (window.innerWidth - listW) - 30 }
        setScrollX(x)
    }

    return (
        <>
            {collection.length != 0 &&
                <div className="productRow">

                    <div className="session-text relative justify-content-between">
                        <div className="col-mb-3">
                            <h1>Minha Coleção ( {collection.length} )</h1>
                            <div className="session-text-span">Confira sua coleção adquirida e comece a viver histórias épicas ...</div>
                        </div>
                        <span href="albums.html">Todo Conteúdo<i className="icon-angle-right ml-3"></i></span>
                    </div>

                    <div className="productRow--left" onClick={handleLeftArrow}> ‹ </div>
                    <div className="productRow--right" onClick={handleRigthArrow}> › </div>
                    <div className="productRow--listarea">
                        <div className="productRow--list" onLoad={theSize}
                            style={
                                {
                                    marginLeft: scrollX,
                                    width: collection.length * newScreen
                                }
                            }>
                            <div >
                                {collection.length > 0 && collection.map((aventura) => (
                                    <div
                                        key={aventura._id}
                                        onClick={() => {

                                            setSelected(aventura)
                                            
                                            //const event = new CustomEvent('selectProductViewer', {
                                            //    detail: aventura,
                                            //});
                                            //window.dispatchEvent(event);
                                        }}
                                        data-toggle="modal"
                                        data-target="#modal--viewer-filme"
                                        className="productRow--item"
                                    >
                                        <img src={aventura.thumb} />
                                        <figcaption>{aventura.title}</figcaption>

                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    );
}

