import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

const NoAuthRouteMiddleware = (props) => {

  //const { token } = useContext(AuthContext)
  const token = localStorage.getItem('@user');
  //const { user } = useContext(UserContext);

  const location = useLocation();

  const authUnassessibleRoutes = ["/login", "/signup"];
  const isAuthUnassessibleRoutes = authUnassessibleRoutes.includes(location.pathname);

  if (token && isAuthUnassessibleRoutes) {
    return <Navigate to="/" />;
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export default NoAuthRouteMiddleware;
