import React, { useState, createContext } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const CartContext = createContext();

const CartProvider = ({ children }) => {
   

    //PEGAR DADOS DO CARRINHO

    const [cart, setCart] = useState({});
    const [cartItems, setCartItems] = useState([]);

    const getCart = async () => {
        try {
            const response = await api.get('/cart/customer');
            const res = response.data;

            if (res.cart === null) { return }

            setCart(res); // CARREGA O CARRINHO DO USUÁRIO CONFORME O TOKEN      
            setCartItems(res.cart.items); // CARREGA OS ITENS DO CARRINHO DO USUÁRIO CONFORME O TOKEN    

        } catch (err) {
            toast.warn("Não há um carrinho associado ao usuário" + err.message);
            localStorage.clear();
        }
    };

    return (
        <CartContext.Provider value={{ cart, cartItems, getCart }}>
            {children}
        </CartContext.Provider>
    )
};

export default CartProvider;