import React, { useState, createContext } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {


    //PEGAR DADOS DO CARRINHO

    const [principal, setPrincipal] = useState();
    const [secoes, setSecoes] = useState([]);
    const [collection, setCollection] = useState([]);
    const [manuals, setManuals] = useState([]);
    const [selected, setSelected] = useState();
    const [selectedProduct, setSelectedProduct] = useState([]);

    const getHome = async () => {
        try {
            const response = await api.get('/product?type=aventura');
            const res = response.data;
            setSecoes(res.produtos);
        } catch (err) {
            toast.warn("Faça Login para Continuar" + err.message);
        }
    };

    const getManuals = async () => {
        try {
            const response = await api.get('/product?type=manual');
            const res = response.data;
            setManuals(res.produtos);
        } catch (err) {
            toast.warn("Faça Login para Continuar" + err.message);
        }
    };

    const getCollections = async () => {
        try {
            const response = await api.get('/transaction/customer');
            const res = response.data;

            const results = res?.myTransactions
                .map(item => item.items.map(codes => codes.code))
                .reduce((acumulador, valorAtual) => {
                    return acumulador.concat(valorAtual)
                }, [])

            if (results === null || undefined) { return }

            setCollection(results) // pega as Aventuras      
            setPrincipal(results[0]);   // pega o Hero      

        } catch (err) {

            toast.warn("Faça Login para Continuar" + err);
        }
    };

    return (
        <ProductContext.Provider value={{ principal, setPrincipal, secoes, setSecoes, collection, setCollection, getHome, getCollections, selected, setSelected, manuals, getManuals }}>
            {children}
        </ProductContext.Provider>
    )

};

export default ProductProvider;