import React from "react";

export const SpinningButton = ({ text }) => {
    return (
        <button className={`btn btn-primary}`} type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {` ${text}` || 'Carregando...'}
        </button>
    )
}
