import React, { useState, useEffect, useContext } from "react";
import './blog.css'
import { Link, useNavigate } from "react-router-dom";
import { WordpressContext } from "../../Contexts/wordpress-context";
import Bestiario from "./bestiario";

const Blog = () => {

    //WORDPRESS

    const { materias, materiaSelecionada, setMateriaSelecionada, getBlog } = useContext(WordpressContext);

    useEffect(() => {
        getBlog()
    }, [])

    const location = useNavigate();

    //NAVEGAÇÃO

    const [scrollX, setScrollX] = useState(0);
    const [newScreen, setNewScreen] = useState(220);

    const theSize = () => {
        let screenSize = window.screen.width;
        let widthList;
        if (screenSize < 760) { widthList = 110 } else { widthList = 480 }
        setNewScreen(widthList);
    }

    useEffect(() => {
        theSize();
        return
    }, []);

    const handleLeftArrow = () => {
        let x = scrollX + Math.round(window.innerWidth / 2);
        if (x > 0) { x = 0; } setScrollX(x);
    }
    const handleRigthArrow = () => {
        let x = scrollX - Math.round(window.innerWidth / 2);
        let listW = materias.length * newScreen;
        if ((window.innerWidth - listW) > x) { x = (window.innerWidth - listW) - 30 }
        setScrollX(x)
    }


    return (

        <>
            <div className="productRow productRow--listarea">

                {false && <div className="row">
                    <Bestiario />
                    <div className="col-md-4 spotfy-embed">
                        <div className="blog-card b">
                            <div className="card-overlay"></div>

                            <div className="blog--info--card">
                                <div className="blog-card-description">
                                    <h1 className="blog-title">Mesas Abertas</h1>
                                    <p className="blog-subtitle">Encontre agora seu novo grupo</p>
                                    <p className="blog-shorts">Encontre agora mestres e jogadores para a sua próxima aventura, ou crie sua própria mesa para jogar...</p>
                                    <span type="button" className="watch-btn"> Em breve</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="session-text relative justify-content-between">
                    <div className="col-mb-3">
                        <h1>Últimas Postagens</h1>
                        <div className="session-text-span">Confira as últimas postagens da abóbora ...</div>
                    </div>
                    <span href="albums.html">Todo Conteúdo<i className="icon-angle-right ml-3"></i></span>
                </div>

                <div className="productRow productRow--listarea ">

                    <div className="productRow">

                        <div className="productRow--left mini" onClick={handleLeftArrow}> ‹ </div>
                        <div className="productRow--right mini" onClick={handleRigthArrow}> › </div>

                        <div className="videos--home--out" style={
                            {
                                marginLeft: scrollX,
                                width: materias.length * newScreen
                            }
                        }>
                            {materias && materias.map((materia, index) => {
                                let a = 'https://media-exp1.licdn.com/dms/image/C4D1BAQGcf3IWftahLA/company-background_10000/0/1570403698813?e=2147483647&v=beta&t=4OASl70eXPVFEBv8kNVKJjs-I-FZdDSZx2zolMQ_IBQ';
                                if (materia._embedded['wp:featuredmedia']) {
                                    a = materia?._embedded['wp:featuredmedia'][0].source_url
                                }
                                return (

                                    <div className="videos--home" key={index}
                                        onClick={() => {
                                            setMateriaSelecionada(materia)
                                            location(`/blog/${materia.slug}`)
                                        }}
                                    >

                                        {
                                            materias &&
                                            <>
                                                <img src={a}></img>

                                                <div className="col-lg-10 videos--home--info">
                                                    <div className="play--info">
                                                        📄
                                                    </div>

                                                    <div className="col-md">
                                                        <div>
                                                            {materia.title.rendered.substring(0, 30) + " ..."}
                                                        </div>
                                                        <div className="video--desc">
                                                            {(materia.excerpt.rendered).substring(0, 70).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n|<p>)/g, '') + " ..."}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                )
                            }
                            )}


                        </div>

                    </div>
                </div>


            </div>


        </>
    )
}

export default Blog;