import React, { useState, createContext, useEffect } from "react";
import wordpressApi from "../services/wordpressApi";

export const WordpressContext = createContext();

const WordpressProvider = ({ children }) => {

    //PEGAR MATÉRIAS DO WORDPRESS

    const [materias, setMaterias] = useState([]);
    const [materiaSelecionada, setMateriaSelecionada] = useState();

    const getBlog = async () => {
        try {
            const response = await wordpressApi.get('/wp-json/wp/v2/posts?_embed');
            const res = response.data;

            setMaterias(res);   // pega as matérias do Blog

        } catch (err) {
            alert("Erro ao processar a requisição" + err);

        }
    };

    return (
        <WordpressContext.Provider value={{ materias, getBlog, materiaSelecionada, setMateriaSelecionada }}>
            {children}
        </WordpressContext.Provider>
    )

};

export default WordpressProvider;