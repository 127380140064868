import React, { useState, createContext, useEffect } from "react";
import { toast } from 'react-toastify';
import youtubeApi from "../services/youtubeApi";

export const YoutubeContext = createContext();

const YoutubeProvider = ({ children }) => {

    //PEGAR VÍDEOS DO CANAL

    const [videos, setVideos] = useState(
        {
            "kind": "youtube#searchListResponse",
            "etag": "ZpcyZLrno4B1i1xrsigQOXvCR0c",
            "regionCode": "BR",
            "pageInfo": {
                "totalResults": 15,
                "resultsPerPage": 15
            },
            "items": [
                {
                    "kind": "youtube#searchResult",
                    "etag": "tDp9VO9qIzpmVx2F3-l-Bs0mmvc",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "ln8NCMKJjL8"
                    },
                    "snippet": {
                        "publishedAt": "2020-05-18T14:37:55Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "O que o RPG de mesa significa para você? - Guilda da abóbora | Pumpkin Studio",
                        "description": "Faça parte da Guilda da abóbora: https://pumpkinstudio.com.br/guilda-da-abobora O que o RPG de mesa significa para você?",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/ln8NCMKJjL8/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/ln8NCMKJjL8/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/ln8NCMKJjL8/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-05-18T14:37:55Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "IqCmQAEBwqx7Ppxyw_QW07tXy08",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "TmurI-zuX5g"
                    },
                    "snippet": {
                        "publishedAt": "2020-03-18T17:47:58Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "Guilda da abóbora | RPG de Mesa todos os meses",
                        "description": "Já imaginou receber uma aventura de RPG de mesa todos os meses de forma ininterrupta? Se sim, então é hora de conhecer a ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/TmurI-zuX5g/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/TmurI-zuX5g/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/TmurI-zuX5g/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-03-18T17:47:58Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "0a89L1zfcg2GAcjH5f8Kcm3hVaM",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "bNzgKEeUC1U"
                    },
                    "snippet": {
                        "publishedAt": "2020-04-15T21:04:05Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "O que vale mais a pena? Improvisar ou Preparar uma Campanha de RPG de mesa? - Pumpkin Studio",
                        "description": "Receba gratuitamente uma das nossas aventuras: https://pumpkinstudio.com.br/e-book-infestacao-noturna Um dos maiores ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/bNzgKEeUC1U/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/bNzgKEeUC1U/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/bNzgKEeUC1U/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-04-15T21:04:05Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "NIJ3GdfuOS36dMx_o4SNHZ4XJIA",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "hJ06MyQg5CI"
                    },
                    "snippet": {
                        "publishedAt": "2020-10-06T21:14:36Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "Como editar e imprimir as cartas exclusivas da Guilda da Abóbora - Pumpkin Studio",
                        "description": "RPG DE MESA | FAÇA O DOWNLOAD: https://pumpkinstudio.com.br/e-book-infestacao-noturna CONHEÇA A GUILDA DE ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/hJ06MyQg5CI/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/hJ06MyQg5CI/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/hJ06MyQg5CI/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-10-06T21:14:36Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "Uw0So94Ovn_twTwysHxjkA3sCS0",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "q6l9WNkBGaA"
                    },
                    "snippet": {
                        "publishedAt": "2020-02-24T00:02:36Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "Bem-Vindo à Pumpkin Studio",
                        "description": "É um prazer ter vocês aqui no nosso canal. Esse vídeo explica um pouco sobre o tipo de conteúdo que você vai ver por aqui.",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/q6l9WNkBGaA/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/q6l9WNkBGaA/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/q6l9WNkBGaA/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-02-24T00:02:36Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "Dy2jGIKJwKSgqu8F_XbE7jxeKwE",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "-bGtYcjvJFk"
                    },
                    "snippet": {
                        "publishedAt": "2019-12-12T20:00:13Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "The Warning: Concept Art Process",
                        "description": "Se você gostou dessa arte, siga nossas mídias sociais para saber mais: Twitter https://twitter.com/pumpkinstudiobr Instagram: ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/-bGtYcjvJFk/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/-bGtYcjvJFk/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/-bGtYcjvJFk/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2019-12-12T20:00:13Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "pzXyTg2uC3OR6DihQa8-W-i-xP8",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "AjAOKlC6CoU"
                    },
                    "snippet": {
                        "publishedAt": "2020-04-08T18:50:12Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "Como desenhar um Boss para o seu RPG de mesa - Speed Paint (#Photoshop)",
                        "description": "Baixe o e-book gratuitamente e enfrente este Boss: https://pumpkinstudio.com.br/e-book-infestacao-noturna Gharuda é o Boss ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/AjAOKlC6CoU/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/AjAOKlC6CoU/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/AjAOKlC6CoU/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-04-08T18:50:12Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "hNUhh7ArTmgm32moBXIgpZgOsAo",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "g9gu8SFO87Y"
                    },
                    "snippet": {
                        "publishedAt": "2019-10-12T15:35:39Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "The Pumpkin - Speed Painting - Photoshop CC 2019",
                        "description": "Processo de pintura digital feita pelo artista Diego Mattos do nosso personagem Pumpkin. Esperamos que apreciem o projeto e ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/g9gu8SFO87Y/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/g9gu8SFO87Y/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/g9gu8SFO87Y/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2019-10-12T15:35:39Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "ExB0_G0aofoG7fZIvMXqaNxMbgE",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "ZiIC_FKL6kw"
                    },
                    "snippet": {
                        "publishedAt": "2020-02-13T22:00:12Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "RPG Character - Johrn Speed Painting - PHOTOSHOP",
                        "description": "E aí pessoal estamos compartilhando com vocês o speed painting do personagem Johrn, o Açougueiro. Se você não sabe quem ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/ZiIC_FKL6kw/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/ZiIC_FKL6kw/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/ZiIC_FKL6kw/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-02-13T22:00:12Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "PM_nycU-dRpHFGaGwuzPfFDw5jI",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "YCMaqmcTvgo"
                    },
                    "snippet": {
                        "publishedAt": "2020-02-08T22:00:14Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "Aventura de RPG de mesa Gratuita - Infestação Noturna e-book",
                        "description": "Acesse o Link e faça DOWNLOAD: http://pumpkinstudio.com.br/e-book-infestacao-noturna/ Uma aventura de RPG ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/YCMaqmcTvgo/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/YCMaqmcTvgo/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/YCMaqmcTvgo/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-02-08T22:00:14Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "AsQm9dTwy_pKsq_6gqdfBVSo3wo",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "IcMrdO7lh5o"
                    },
                    "snippet": {
                        "publishedAt": "2020-04-19T18:15:56Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "RPG DE MESA | Infestação Noturna - Aventura Gratuita Para Download | E-book",
                        "description": "RPG DE MESA | FAÇA O DOWNLOAD: https://pumpkinstudio.com.br/e-book-infestacao-noturna/ Uma aventura de RPG ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/IcMrdO7lh5o/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/IcMrdO7lh5o/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/IcMrdO7lh5o/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2020-04-19T18:15:56Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "zO8VpDJidBZuPuviSwDSZyFH8is",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "BI5rg1ZAv_U"
                    },
                    "snippet": {
                        "publishedAt": "2019-12-17T00:30:10Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "Star Wars Battlefront 2 - Dica Pumpkin",
                        "description": "Com poucos dias para estrear nos cinemas Star Wars The Rise of Skywalker, resolvemos dar uma dica de game para os fãs da ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/BI5rg1ZAv_U/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/BI5rg1ZAv_U/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/BI5rg1ZAv_U/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2019-12-17T00:30:10Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "iAU-203BaH_yj4EtZYKZBTQr_k8",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "izYavSnzUek"
                    },
                    "snippet": {
                        "publishedAt": "2019-11-26T17:23:47Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "Happy Halloween - Speed Drawing | Photoshop 2020",
                        "description": "Speed drawing do nosso querido Jack Pumpkin empolgado ao ver o Sleepy Hollow. Deixe o seu like e seu comentário.",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/izYavSnzUek/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/izYavSnzUek/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/izYavSnzUek/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2019-11-26T17:23:47Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "sEUfm7EEks05dZe_l7gfVtbrLzY",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "7sf6b5ZuSZ8"
                    },
                    "snippet": {
                        "publishedAt": "2019-12-23T21:00:11Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "Overcooked 2 - Dica Pumpkin",
                        "description": "Fala aí galera...Tudo beleza? Hoje trouxemos para vocês um jogo muito louco: Overcooked 2. Desenvolvido pela Ghost Town ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/7sf6b5ZuSZ8/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/7sf6b5ZuSZ8/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/7sf6b5ZuSZ8/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2019-12-23T21:00:11Z"
                    }
                },
                {
                    "kind": "youtube#searchResult",
                    "etag": "h8EvMgO7eVUyxFknA62ldLUn9OA",
                    "id": {
                        "kind": "youtube#video",
                        "videoId": "_6cNqJ9hL84"
                    },
                    "snippet": {
                        "publishedAt": "2019-11-28T23:30:11Z",
                        "channelId": "UCUdDs9xg9gh98wrwbLuWe1w",
                        "title": "The Explorer - Character Design | Speed Drawing",
                        "description": "Este é mais um conceito sobre um dos personagens do jogo. Ainda estamos escolhendo o estilo artístico, mas a equipe decidiu ...",
                        "thumbnails": {
                            "default": {
                                "url": "https://i.ytimg.com/vi/_6cNqJ9hL84/default.jpg",
                                "width": 120,
                                "height": 90
                            },
                            "medium": {
                                "url": "https://i.ytimg.com/vi/_6cNqJ9hL84/mqdefault.jpg",
                                "width": 320,
                                "height": 180
                            },
                            "high": {
                                "url": "https://i.ytimg.com/vi/_6cNqJ9hL84/hqdefault.jpg",
                                "width": 480,
                                "height": 360
                            }
                        },
                        "channelTitle": "Pumpkin Studio",
                        "liveBroadcastContent": "none",
                        "publishTime": "2019-11-28T23:30:11Z"
                    }
                }
            ]
        }
    );
    const [selectedVideo, setSelectedVideo] = useState();

   /*
    const getVideos = async () => {
        try {
            const response = await youtubeApi.get('/search?maxResults=15&type=video&channelId=UCUdDs9xg9gh98wrwbLuWe1w&key=AIzaSyDJ6S9VX5DYyOsNIPzeewcNwKilmXdspjs&part=id,snippet');
            const res = response.data;

            setVideos(res); // CARREGA OS VÍDEOS      


        } catch (err) {
            toast.warn("Não foi possível carregar os vídeos" + err.message);
            
        }
    };
*/


    return (
        <YoutubeContext.Provider value={{ videos, selectedVideo, setSelectedVideo }}>
            {children}
        </YoutubeContext.Provider>
    )

};

export default YoutubeProvider;