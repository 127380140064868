import React, { Component } from 'react';
import ViewSDKClient from '../PDF/ViewSDKClient';

const FullWindow = ({selected}) => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
        viewSDKClient.previewFile("pdf-div", {
            showAnnotationTools: false,
            showDownloadPDF: false,
            showPrintPDF: false
        }, selected);
    });

    return <div id="pdf-div" className="full-window-div" />;
}

export default FullWindow;
