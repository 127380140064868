import React from "react";
import './CancelModal.css'

export const CancelModal = ({ showModal, setShowModal }) => {
    const closeModal = () => setShowModal(false);

    return (
        <div className={`modal fade ${showModal ? 'show' : ''}`} id="cancelModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
            <div className="modal-dialog" role="document">
                <div className="modal-content cancel-modal">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Cancelar Assinatura</h5>
                        <button type="button" className="close" onClick={closeModal} aria-label="Fechar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Tem certeza que deseja cancelar sua assinatura? Isso fará você perder seu status e acessos no discord após o vencimento e todos os demais benefícios.
                        Sua participação é muito importante para continuarmos criando mundos fantásticos.
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={closeModal} style={{ color: 'white' }}>Sair</button>
                        <button type="button" className="btn btn-danger" style={{ color: 'white' }}>Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
