import React, { useState, createContext } from "react";
import api from '../../src/services/api';

export const SignatureContext = createContext();

const SignatureProvider = ({ children }) => {

    //PEGAR DADOS DO CARRINHO

    const [plans, setPlans] = useState([]);
    const [plan, setPlan] = useState(null);

    const getPlans = async () => {
        try {
            const response = await api.get('/plans');
            setPlans(response.data.planos);
        } catch (err) {
            console.error("Não foi possível recuperar os Planos" + err.message);
        }
    };



    return (
        <SignatureContext.Provider value={{ plans, setPlans, plan, setPlan, getPlans }}>
            {children}
        </SignatureContext.Provider>
    )

};

export default SignatureProvider;