import React, { useContext, useEffect } from "react";
import '../Header.css'

import { UserContext } from "../../../Contexts/user-context";


export const UserName = React.memo(
    function UserName() {

        const { userInfo, getMyAccount } = useContext(UserContext);

        useEffect(() => {
            getMyAccount();
        }, []);

        return (
            <div className="header--user-name">{userInfo?.name || "Usuário"}</div>
        )
    }
)