import React, { useEffect, useContext } from 'react';
import { ProductContext } from "../../Contexts/product-context";

import './Home.css';

import Hero from '../../components/Hero/Hero';
import Products from '../../components/Products/Products';
import ProductModal from '../../components/ProductModal';
import ViewerModal from '../../components/viewerModal';
import { BookLoader } from '../../components/utils/BookLoader/BookLoader';
import MyAdventures from '../../components/MyAdventures/myAdventures';
import Videos from '../../components/Videos/videos';
import Blog from '../../components/Blog/blog';
import Bestiario from '../../components/Bestiario'
import MonsterSectionScroll from '../../components/MonsterSectionScroll/monster-section-scroll';
import Manuals from '../../components/Products/Manuals';
import RaceSectionScroll from '../../components/RaceSectionScroll/race-section-scroll';


export default () => {

  const { collection } = useContext(ProductContext); 

  return (

    <>
      <ViewerModal />
      <ProductModal />
      <div className="page">
        <Hero />
        <section className="productRow--hero">
          <MonsterSectionScroll />
          <RaceSectionScroll />
          <MyAdventures />
          <Products />
          <Manuals />
          <Bestiario/>

          {/* <Videos /> */}
          <Blog /> 
        </section>
      </div>
      {collection.length <= 0 && <BookLoader />}
    </>

  );
}

