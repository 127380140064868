import React, { useEffect } from 'react';
import './styles/globals.css' //CSS GLOBAL DA APLICAÇÃO

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

//COMPONENTS

import Header from './components/Header';
import Footer from './components/Footer';

//PAGES

import Login from './pages/Authentication/Login';
import Cadastro from './pages/Authentication/Cadastro';
import Home from './pages/Home';
import Adventure from './pages/Adventure/Adventure';
import BlogPage from './pages/Blog-Page/blog-page'
import MonsterPage from './pages/Monster-Page/monster-page'
import PromoMonsterPage from './pages/promotional-monster-page/promo-monster';
import Conta from './pages/Conta';
import Checkout from './pages/Checkout';
import PasswordChange from './pages/PasswordChange';
import ForgotPassword from './pages/ForgotPassword';
import Signature from './pages/Signature/signature';
import SignatureCheckout from './pages/SignatureCheckout';
import VideoSingle from './pages/video-single';
import { Materiais } from './pages/Materiais/Materiais';
import { GuildLandingPage } from './pages/Guild-Landing-Page/guild-landing-page';
import { NotFound } from './pages/Not-Found/page404';
import { AllMonsters } from './pages/AllMonsters';
import Authmiddleware from './middlewares/auth-midleware';
import NoAuthRouteMiddleware from './middlewares/no-auth-middleware';
import EmailCheckPage from './pages/email-check-page/email-check';
import SpecialRouteMiddleware from './middlewares/special-middleware';
import { Races } from './pages/Races/races';

// SCRIPTS

const Wrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Cadastro /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/change-password/:id/:token", element: <PasswordChange /> },
  { path: "/signature", element: <Signature /> }
]

const authProtectedRoutes = [
  { path: "/", element: <Home /> },
  { path: "/blog/:slug", element: <BlogPage /> },
  { path: "/monster/:slug", element: <MonsterPage /> },
  { path: "/monster/monster-guild", element: <PromoMonsterPage /> },
  { path: "/races/:slug", element: <Races /> },
  { path: "/minha-conta", element: <Conta /> },
  { path: "/checkout", element: <Checkout /> },
  { path: "/video/:id", element: <VideoSingle /> },
  { path: "/materiais", element: <Materiais /> },
  { path: "/guild", element: <GuildLandingPage /> },
  { path: "/monsters", element: <AllMonsters /> },
  { path: "/adventure/:slug", element: <Adventure /> },
  //{ path: "/signature", element: <Signature /> },
  { path: "/signature-checkout", element: <SignatureCheckout /> },
  { path: "*", element: <NotFound /> }
]

const specialRoutes = [
  { path: "/email-check", element: <EmailCheckPage /> },
]

const AppRoutes = () => {

  return (
    <Router>
      <Wrapper>
        {<Header />}
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NoAuthRouteMiddleware>
                  {route.element}
                </NoAuthRouteMiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  {route.element}
                </Authmiddleware>}
              key={idx}
              exact={true}
            />
          ))}

          {specialRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <SpecialRouteMiddleware>
                  {route.element}
                </SpecialRouteMiddleware>}
              key={idx}
              exact={true}
            />
          ))}

        </Routes>
        {<Footer />}
      </Wrapper>
    </Router>
  );
};

export default AppRoutes;
