import React from 'react';
import { useLocation } from 'react-router-dom';
import './SocialButtons.css';
import whatsappLogo from "../../../assets/whatsapp-logo-1.png"
import discordLogo from "../../../assets/discord-white-icon.webp"

const discordLink = process.env.REACT_APP_LINK_PARA_O_CONVITE_DO_DISCORD
const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER

const SocialButtons = () => {
  const pathLocation = useLocation()

  if (pathLocation.pathname !== "/") {
    return null;
  }

  const handleDiscordClick = () => {
    // Redirecione para o convite do Discord
    window.location.href = discordLink;
  };

  const handleWhatsappClick = () => {
    // Abra o WhatsApp Web ou o aplicativo móvel
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href = `whatsapp://send?phone=${whatsappNumber}`;
    } else {
      window.open(`https://web.whatsapp.com/send?phone=${whatsappNumber}`, '_blank');
    }
  };

  return (
    <div className="social-buttons">

      {/* <button className="whatsapp-button" onClick={handleWhatsappClick}>
        <img src={whatsappLogo} alt="WhatsApp" />
      </button> */}

      <button className="discord-button" onClick={handleDiscordClick}>
        <img src={discordLogo} alt="Discord" />
      </button>

    </div>
  );
};

export default SocialButtons;
