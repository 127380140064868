
import React, { useContext } from 'react';
import './promo-monster.css'
import { useParams, useNavigate } from "react-router-dom";
import { MonsterContext } from '../../Contexts/monster-context';

const PromoMonsterPage = () => {

    const location = useNavigate();
    const standard_img = 'https://pumpkinstudio.com.br/wp-content/uploads/2021/04/Home_CardsGuilda.jpg';
    const { selectedMonster } = useContext(MonsterContext);


    return (
        <div className="background-black header-padding">         

                <section className="relative"
                    style={
                        {
                            backgroundImage: `url('assets/img/demo/b3.jpg')`
                        }}>
                    
                        
                            <div className="col-md-10">
                                <div className="row my-4 pt-1">
                                    <div className="col-md-3">
                                        <img className='promo-monster-thumb' src={selectedMonster?.thumb || standard_img} alt="/"/>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="d-md-flex align-items-center justify-content-between">
                                            <h1 className="my-3 text-white ">{selectedMonster?.name}</h1>
                                            <div className="ml-auto mb-2">
                                                <a href="#" className="snackbar"
                                                ><i className="icon-bookmark s-24"></i></a>

                                            </div>
                                        </div>

                                        <div className="text-white my-2">
                                            <p>Este monstro está disponível apenas para Membros e Heróis | Heroínas da Guilda da Abóbora.< br />
                                                Considere entrar para a Guilda, são muitos benefícios para você e para o seu grupo. Tenha acesso ao acervo completo de monstros, mapas, itens e tudo o que contempla o universo da Guilda da Abóbora.</p>
                                        </div>
                                        <div className="pt-3">
                                            <span className="featured--watchbutton button-guild"
                                            onClick={() => {
                                                location('/signature')
                                            }}>
                                                Entrar na Guilda 🎃
                                            </span>
                                            {/* <div
                                                className="featured--watchbutton"
                                                data-dismiss="modal"
                                                onClick={() => {
                                                    location('/guild')
                                                }}
                                            >📜 Saiba Mais</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                     
               

                </section>



            
        </div>
    )
};

export default PromoMonsterPage;
