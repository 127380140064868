import React, { useContext, useEffect } from "react";
import {useParams, useNavigate} from "react-router-dom";
import FullWindow from "./EmbedMode/FullWindow";
import './Adventure.css';

import { ProductContext } from '../../Contexts/product-context';

const Adventure = () => {
    const { collection, getCollections, selected, setSelected } = useContext(ProductContext);
    const { slug } = useParams();

    useEffect(() => {
        getCollections()
    }, [])

    const selectAdventure = () => {
        if (!selected && collection) {
            const filterAdventure = collection.filter(element => element.slug === slug)
            setSelected(filterAdventure[0])
        }
    }

    useEffect(() => {
        selectAdventure()
    }, [collection, selected])
   
    return (
        <div className="adventure-pdf">
            <FullWindow selected={selected} />
        </div>        
    )
}

export default Adventure;