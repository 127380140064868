import React from 'react';

const Aventuras = ({ aventura }) => {
  const selectProduct = () => {
    const event = new CustomEvent('selectProduct', {
      detail: aventura,
    });

    window.dispatchEvent(event);
  };

  return (
    <div
      onClick={selectProduct}
      
      data-toggle="modal"
      data-target="#modal-filme"
      className="productRow--item"
    >
      <img src={aventura.thumb} />
      <figcaption>{aventura.title}</figcaption>
      
    </div>    
  );
};

export default Aventuras;
