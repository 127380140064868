import React, { useContext, useEffect, useState } from "react";
import './bestiario.css'
import { Link } from "react-router-dom";

import { MonsterContext } from "../../Contexts/monster-context";
import { UserContext } from "../../Contexts/user-context";

const BestiarioComponent = () => {

    const { monsters, paidMonsters, setMonsters, getMonsters, getPaidMonsters, setSelectedMonster } = useContext(MonsterContext);
    const { user } = useContext(UserContext);

    const [monsterList, setMonsterList] = useState()
    const [filteredString, setFilteredString] = useState('')

    useEffect(() => {
        getMonsters()
        getPaidMonsters()
    }, [user])

    useEffect(() => {

        if (monsters && paidMonsters && user?.subscriber === false) {

            setMonsterList([...monsters, ...paidMonsters])
            return
        }
        setMonsterList(monsters)
    }, [monsters])

    return (
        <div className="col-md-12">

            <div className="bestiary-container">

                <div className="session-text relative justify-content-between">
                    <div className="col-mb-3">
                        <h1>Bestiário da Guilda</h1>
                        <div className="session-text-span">Confira os monstros de Fallgard, o Mundo da Guilda da Abóbora ...</div>
                    </div>
                    <span href="albums.html">Todo Conteúdo<i className="icon-angle-right ml-3"></i></span>
                </div>

                <div className="" id="myBtnContainer">
                    <button className="btn active" > Bestiário</button>
                    {/*<button className="btn" > Herbologia</button>
                    <button className="btn" > Grimório</button>
                    <button className="btn" > Itens</button>
                    <button className="btn" > Mapas</button>*/}
                    <input type="text" className="search-input-bestiary" id="myInput" placeholder="Pesquisar.."
                        onChange={
                            (event) => {
                                setFilteredString(event.target.value.toUpperCase())
                            }
                        }></input>
                </div>

                <div className="" id="tableWrapper">
                    <div id="tableScroll">
                        <div className="">
                            <div className="tab-content" id="v-pills-tabContent1">

                                <div className="tab-pane fade show active" id="w2-tab1" role="tabpanel" aria-labelledby="w2-tab1">
                                    <div className="playlist pl-lg-3 pr-lg-3">
                                        <div className="m-1 my-4 no-link tab-content-fonts">

                                            {monsterList && monsterList
                                                .sort((a, b) => {
                                                    return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
                                                })
                                                .filter(name => name.name
                                                    .toUpperCase()
                                                    .includes(filteredString))
                                                .map(monster => (

                                                    <Link
                                                        to={`/monster/${monster?.slug || "monster-guild"}`}
                                                        key={monster?._id}
                                                    >
                                                        <div className="d-flex align-items-center monster-select" onClick={() => {
                                                            setSelectedMonster(monster)
                                                        }}>

                                                            <div className="col-0">
                                                                {monster.paid === false && <div className="icon s-28 flex-v-center">📂</div>}
                                                                {monster.paid && user?.subscriber === false && <div className="icon s-28 flex-v-center">🔒</div>}
                                                                {monster.paid && user?.subscriber === true && <div className="icon s-28 flex-v-center">📂</div>}
                                                            </div>
                                                            <div className="col-md-7">
                                                                <figure className="avatar-md float-left  mr-3 mt-1">
                                                                    <img className="r-3" src={monster.thumb} alt="" />
                                                                </figure>
                                                                <h6>{monster.name}</h6>
                                                                {monster.specie} {monster.size}, {monster.alignment}
                                                            </div>
                                                            <div className="col-md-2 d-none d-lg-block">
                                                                <div className="d-flex">
                                                                    <span className="ml-2 flex-v-center">Nível do desafio: {String(monster.first_box_info.nd)}</span>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-auto ml-auto d-none d-lg-block">
                                                                {monster.paid === false && <button href="#" className="btn-ficha-completa btn-outline-primary btn-sm">Ficha Completa</button>}
                                                                {monster.paid && user?.subscriber === false && <button href="#" className="btn-monstro-guilda btn-sm">Monstro da Guilda</button>}
                                                                {monster.paid && user?.subscriber === true && <button href="#" className="btn-ficha-completa btn-outline-primary btn-sm">Ficha Completa</button>}
                                                            </div>

                                                        </div>
                                                    </Link>
                                                ))}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default BestiarioComponent;