

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import './ForgotPassword.css';


function ForgotPassword() {  

  const [email, setEmail] = useState({
    email: ''
  });

  const changePassEmail = async () => {

    window.addEventListener('submit', function (e) { e.preventDefault() })
    const btnSend = document.querySelector('#btn_send');

    const validateFields = document.querySelectorAll('#frm-signin :invalid').length;
    if (validateFields !== 0) { return false }

    btnSend.classList.add("loadingBtn");

    await api.post('customer/forgot-password', email)
      .then((response) => {
        toast.success("Confira seu e-mail para alterar sua senha");
      })
      .catch((reason) => {
        if(reason?.response?.status === 404) {
            return toast.warn(`${reason?.response?.data?.message}`);
        }
        toast.warn(`O e-mail não pode ser enviado, entre em contato com o suporte: ${reason?.response?.data}`);
      });
  };

  return (

    <div className="container-login-page">
      <div className="frm-main-container">
        <div className="inner">

          <span id='email-auth-title'>Recuperação de Senha</span>
          <span id='email-auth-span'>Insira o e-mail cadastrado para alterar sua senha</span>
          <form action="" className="frm-signin" id="frm-signin">
            <div className="frm-group">
              <input
                type='email'
                autoComplete='off'
                id='input-email'
                size="16"
                className="form-control form-control-lg"
                placeholder="Insira seu e-mail cadastrado"
                required
                onChange={(e) => {
                  setEmail({ email: e.target.value })
                }}
              />
            </div>

            <div className="frm-group">
              <br />
              <button className="" id="btn_send" type="submit" onClick={() => {changePassEmail()}}>Confirmar</button>
            </div>
            <hr />            
          </form>

        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
