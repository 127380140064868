import React, { useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import './ViewerModal.css';

import { ProductContext } from '../../Contexts/product-context';

import { CloseBtn } from '../utils/close-btn/CloseBtn';

const ViewerModal = () => {

  const { selected } = useContext(ProductContext);
  const location = useNavigate();

  return (
    <>
      <div className="modal fade" id="modal--viewer-filme">
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-hero"
              style={{ backgroundImage: `url(${selected?.hero})` }}
            >
              <div className="modal-hero-pos">
                <CloseBtn dataDismiss={"modal"} />
                <img src={selected?.title_lettering} alt={selected?.title} className="title-lettering"/>
                <div className="col-12 modal-hero-infos pl-0">
                  <span
                    className="featured--watchbutton"
                    data-dismiss="modal"
                    onClick={() => {
                      location(`/adventure/${selected.slug}`)
                    }}
                  >🎲 Jogar</span>
                </div>
              </div>
            </div>
            <div className="modal-infos">
              <div className="container">
                <div className="row selected--modal--contents">
                  <div className="col-7" >
                    <span className='modal--sinopse'>Sinopse</span>
                    <p className="filme_descricao">{selected?.description}</p>
                  </div>
                  <div className="col-5">
                    <p className="filme_elenco">
                      {/* Autor: <span>{" " + selected?.autors?.join(', ')}</span>
                      <br />
                      Direção de Arte: <span>{selected?.credits?.join(', ')}</span> */}
                      Extras: <span>{" " + selected?.includes?.join(', ')}</span>
                    </p>

                    <div
                      className="featured--watchbutton"
                      data-dismiss="modal"
                      onClick={() => {
                        location('/materiais')
                      }}
                    >📜 Extras</div>

                  </div>

                </div>
              </div>
            </div>


            <div className="modal-infos">
              <div className="container">
                <div className="row selected--modal--contents ">
                  <div className='col-12 selected-bottom'></div>
                  <br />

                  <div className="col-12">

                  </div>
                </div>


              </div>
            </div>


          </div>
        </div>
      </div>

      <div className="modal fade" id="modal--open-pdf">
        <div className="modal-dialog modal-dialog-pdf">
          <div className="modal-content modal-content-pdf">
            <div className='modal-header-pdf'>
              <CloseBtn dataDismiss={'modal'} />
            </div>
            <div className="modal-body">

              <iframe id='pdf--iframe' src={`${selected?.url}#toolbar=1`} align="middle"> </iframe>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ViewerModal;
