import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

import '../Header.css'

const Account = React.memo(
    function Account() {

        const [openUser, setOpenUser] = useState(false);
        const userRef = useRef([]);

        const handler = (e) => {
            if (!userRef.current?.contains(e.target)) {
                setOpenUser(false);
            }
        }

        useEffect(() => {
            document.addEventListener('mousedown', handler)
            return () => {
                document.removeEventListener('mousedown', handler)
            }
        }, [openUser])

        return (

            <div className="header--user" ref={userRef} >
                <span onClick={() => { setOpenUser(!openUser) }}>
                    <img id="userChar" src="https://pbs.twimg.com/profile_images/1181594688568078338/MuEfPDZ7_400x400.jpg" alt="usuario" />
                </span>


                {openUser &&
                    <>
                        <ul className="accountList" id="accountList">
                            <li onClick={
                                () => {
                                    setOpenUser(false)
                                }
                            }><span className="accountList--item" href=""><Link to="/minha-conta">Minha Conta</Link></span></li>
                            <li><span className="accountList--item" href=""
                                onClick={
                                    function logout() {
                                        localStorage.clear();
                                        window.location.reload();
                                    }
                                }>Sair
                            </span>
                            </li>
                        </ul>
                    </>}
            </div>

        )
    }
)

export default Account;