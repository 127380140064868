import React from 'react';
import axios from 'axios';
//import toast from 'helpers/toast';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
});

const throwErrorToastAndRedirectToLogin = () => {

  // Limpar os tokens, se necessário
  localStorage.removeItem('@user');
  localStorage.removeItem('@refreshToken');
  localStorage.removeItem('@subscriber');
  //toast.fail('Sessão expirada: faça login novamente')

  // Redirecionar para a página de login usando React Router
  window.location.href = '/login';

  return <></>
};

const throwErrorToastAndInactiveAndRedirectToPayment = () => {
  // Limpar os tokens, se necessário
  localStorage.removeItem('@subscriber');
  localStorage.setItem('@subscriber', 'false');
  //toast.fail('Plano inexistente ou expirado: adquira um plano para continuar')
  //window.location.href = '/purchase-plan';

  return <></>
};

const getToken = () => {
  const token = localStorage.getItem('@user');
  return token ? token : null;
};

const getRefreshToken = () => {
  const refreshToken = localStorage.getItem('@refreshToken');
  return refreshToken ? refreshToken : null;
};

const updateToken = async () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const config = {
        headers: {
          'Refresh-Token': refreshToken,
        },
      };
      const response = await api.post('/customer/refreshToken', null, config);

      const newToken = JSON.stringify(response.headers.authorization);
      localStorage.setItem('@user', JSON.parse(newToken));
      const newRefreshToken = JSON.stringify(response.headers["refresh-token"]);
      localStorage.setItem('@subscriber', JSON.parse(newRefreshToken));

      return JSON.parse(newToken);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throwErrorToastAndRedirectToLogin();
      } else {
        throwErrorToastAndRedirectToLogin();
      }
    }
  }
  else {
    localStorage.removeItem('@user');
    localStorage.removeItem('@userInfo');
    window.location.reload()
  }
  return null;
};

api.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      //config.headers = { ...config.headers, Authorization: token };
      config.headers = { ...config.headers, 'x-access-token': `${token}` }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === '/customer/authenticate') {
      return Promise.reject(error);
    }

    // Se a resposta retornar erro 401 (Unauthorized) e não tiver sido tentada uma nova solicitação (retried), tenta refresh
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newToken = await updateToken();

      if (newToken) {
        // Reconfigura o header Authorization com o novo token atualizado
        originalRequest.headers.Authorization = newToken;

        // Reenvia a requisição original com o novo token
        return axios(originalRequest);
      }
    }

    if (error.response.status === 402) {
      throwErrorToastAndInactiveAndRedirectToPayment()
    }

    return Promise.reject(error);
  }
);

export default api;