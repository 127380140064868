import React from "react";
import './BookLoader.css';

export const BookLoader = () => {

    return (
        <div className="book_preload">
            <div className="book">
                <div className="book__page"></div>
                <div className="book__page"></div>
                <div className="book__page"></div>
            </div>
        </div>
    )
}